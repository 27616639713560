import { IService } from "@Models/interfaces/services";
import { PieValueType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import { getServices_service } from "@Services/services";

export const getServicesReport = async (
  setters: {
    setToaster: (message: string, status: number, loading: boolean) => void;
    setPages: (pages: number) => void;
    setServices: (services: IService[]) => void;
    setTopServices: (services: IService[]) => void;
    setPiegraphServicesData: (
      piegraphServicesData: MakeOptional<PieValueType, "id">[]
    ) => void;
    setServicesFetchResponse: (response: IFetchReportResponse) => void;
  },
  payload: {
    loungeId: string;
    fromDate: string;
    toDate: string;
    page: number;
  }
) => {
  const {
    setToaster,
    setServices,
    setPages,
    setTopServices,
    setPiegraphServicesData,
    setServicesFetchResponse,
  } = setters;

  const response: IResponse = await getServices_service(payload);

  setServicesFetchResponse({
    status: response.success ? "success" : "error",
    message: response.user_message,
  });

  if (!response.success) {
    setToaster(response.user_message, response.status, false);
    return response;
  }

  const services: IService[] = response.data.services.map((service) => {
    return {
      id: service.id,
      name: service.name,
      quantity: service.quantity,
      totalRevenue: service.totalRevenue,
      variants: service.variants.map((variant) => {
        return {
          id: variant.id,
          name: variant.name,
          price: variant.price,
          totalRevenue: variant.totalRevenue,
          quantity: variant.quantity,
          appointments: variant.appointments,
        };
      }),
    };
  });

  const topServices: IService[] = services
    .sort((a, b) => b.totalRevenue - a.totalRevenue)
    .slice(0, 10);

  const piegraphServicesData: MakeOptional<PieValueType, "id">[] =
    topServices.map((service) => {
      return {
        id: service.id,
        value: service.totalRevenue,
        label: service.name,
      };
    });

  setServices(services);
  setTopServices(topServices);
  setPiegraphServicesData(piegraphServicesData);
};
