import { STATUS_OK } from "@Models/httpsStatus";
import { ISaleBox } from "@Models/interfaces/saleboxes";
import { useSaleBoxesStore } from "@Stores/saleBoxesStore";
import { useSaleConfigStore } from "@Stores/saleConfigStore";
import { getSaleBoxes_service } from "@Services/saleBox";

export const getSaleBoxes = async () => {
  const response = await getSaleBoxes_service();

  if (response.status !== STATUS_OK) {
    return response;
  }

  const formattedResponse: ISaleBox[] = response.data.saleBoxList?.map(
    (saleBox: any) => {
      return {
        id: saleBox.idSaleBox,
        name: saleBox.saleBoxName,
        isOpen: saleBox.estado === 1,
        hasElectronicBill: saleBox.electronicBilling,
        businessName: saleBox.businnesName,
        businessAddress: saleBox.businneAddress,
        ruc: saleBox.ruc,
        localName: saleBox.localName,
        needsCashCount: saleBox.cashCountConflict,
        isDowngraded: Boolean(saleBox.isDowngrade),
      };
    }
  );

  useSaleBoxesStore.getState().updateSaleBoxes(formattedResponse);
  useSaleConfigStore
    .getState()
    .updateElectronicBillingAmmount(
      response.data.amountInvoiceDocument.billingDocumentStock
    );
  useSaleConfigStore.getState().updateElectronicBilling({
    lastPurchaseDate: response.data.amountInvoiceDocument.lastPurchase,
    isRunningOutOfDocuments: response.data.amountInvoiceDocument.alert20,
  });

  return {
    status: STATUS_OK,
    message: "Cajas obtenidas correctamente",
    data: formattedResponse,
  };
};
