// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fTFZ4lRf0ERTsTQV_Tsv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.TOA56W7X8X8xr0ASlRBs {
  width: 100%;
  text-align: left;
  color: #392b75;
  font: 600 0.8rem/1.2rem "Mulish", sans-serif;
}

.F4Dx8DRoH0ebgbyq2x9s {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.be9dVvrvOlKnXl6Wz6PW {
  width: 100%;
  min-width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/ProgramReportModal/ProgramReportModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,8BAA8B;EAC9B,qBAAqB;EACrB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".form {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 1rem;\n}\n\n.form_row_label {\n  width: 100%;\n  text-align: left;\n  color: #392b75;\n  font: 600 0.8rem/1.2rem \"Mulish\", sans-serif;\n}\n\n.form_row {\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-end;\n  flex-wrap: wrap;\n  gap: 0.8rem;\n}\n\n.form_row_input_container {\n  width: 100%;\n  min-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `fTFZ4lRf0ERTsTQV_Tsv`,
	"form_row_label": `TOA56W7X8X8xr0ASlRBs`,
	"form_row": `F4Dx8DRoH0ebgbyq2x9s`,
	"form_row_input_container": `be9dVvrvOlKnXl6Wz6PW`
};
export default ___CSS_LOADER_EXPORT___;
