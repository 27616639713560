import React, { useEffect } from "react";
import { getLounges } from "@Adapters/getLounges.adapter";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";
import {
  IClientsReportStore,
  useClientsReportStore,
} from "@Stores/useClientsReportStore";
import { getClientsReport } from "@Adapters/getClientsReport.adapter";
import { getClientsCategories } from "@Adapters/getClientsCategories.adapter";

const useLoadClientReport = () => {
  const { loungesFetchResponse, setLoungesFetchResponse, lounges, setLounges } =
    useLoungesStore() as ILoungesStore;
  const {
    currentPage,
    setPages,
    filter,
    setFilter,
    setClients,
    setClientsCategories,
    clientsCategoriesFetchResponse,
    setClientsCategoriesFetchResponse,
    clientsFetchResponse,
    setClientsFetchResponse,
  } = useClientsReportStore() as IClientsReportStore;
  const { setToaster } = useToasterStore() as IToasterStore;

  const loadClientReport = async (tempFilter?) => {
    const localFilter = filter.loungeId ? filter : tempFilter;
    setClientsFetchResponse({
      status: "loading",
      message: null,
    });

    const payload = { ...localFilter, page: currentPage };
    const setters = {
      setPages,
      setToaster,
      setClients,
      setClientsFetchResponse,
    };
    await getClientsReport(setters, payload);
  };

  const setInitialFilter = async (localLounges) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const formattedLastDate = `${currentDate.getFullYear() - 1}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const loungeId = localLounges[0]?.value;
    const newValues = {
      loungeId: loungeId || "",
      categoryId: "",
      dateFilterType: 2,
      fromDate: formattedLastDate,
      toDate: formattedDate,
    };
    setFilter(newValues);
    return newValues;
  };

  useEffect(() => {
    if (clientsCategoriesFetchResponse.status !== "success")
      (async () => {
        setClientsCategoriesFetchResponse({
          status: "loading",
          message: null,
        });
        const response = await getClientsCategories(setToaster);
        if (response.success) setClientsCategories(response.data);
        setClientsCategoriesFetchResponse({
          status: response.success ? "success" : "error",
          message: response.user_message,
        });
      })();

    if (loungesFetchResponse.status !== "success") {
      (async () => {
        setLoungesFetchResponse({
          status: "loading",
          message: null,
        });
        const response = await getLounges(setToaster);
        if (response.success) {
          setLounges(response.data);
          const initialFilter = await setInitialFilter(response.data);
          await loadClientReport(initialFilter);
        }
        setLoungesFetchResponse({
          status: response.success ? "success" : "error",
          message: response.user_message,
        });
      })();
    } else {
      (async () => {
        const initialFilter = await setInitialFilter(lounges);
        if (clientsFetchResponse.status !== "success") {
          loadClientReport(initialFilter);
        }
      })();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loadClientReport };
};

export default useLoadClientReport;
