import {
  IService,
  IPieGraphData,
  IServicesFilter,
} from "@Models/interfaces/services";
import { PieValueType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import { create } from "zustand";

export interface IServicesReportStore {
  filter: IServicesFilter;
  setFilter: (filter: IServicesFilter) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  pages: number;
  setPages: (pages: number) => void;
  services: IService[];
  setServices: (services: IService[]) => void;
  topServices: IService[];
  setTopServices: (services: IService[]) => void;
  piegraphServicesData: MakeOptional<PieValueType, "id">[];
  setPiegraphServicesData: (
    piegraphServicesData: MakeOptional<PieValueType, "id">[]
  ) => void;
  servicesFetchResponse: IFetchReportResponse;
  setServicesFetchResponse: (response: IFetchReportResponse) => void;
}

export const useServicesReportStore = create((set) => ({
  filter: { loungeId: "", fromDate: "", toDate: "" },
  setFilter: (filter: IServicesFilter) => set({ filter }),
  currentPage: 1,
  setCurrentPage: (page: number) => set({ currentPage: page }),
  pages: 1,
  setPages: (pages: number) => set({ pages }),
  services: [],
  setServices: (services: IService[]) => set({ services }),
  topServices: [],
  setTopServices: (topServices: IService[]) => set({ topServices }),
  piegraphServicesData: [],
  setPiegraphServicesData: (piegraphServicesData: IPieGraphData) => {
    set({ piegraphServicesData });
  },
  servicesFetchResponse: {
    status: "loading",
    message: "",
  },
  setServicesFetchResponse: (response: IFetchReportResponse) =>
    set({ servicesFetchResponse: response }),
}));
