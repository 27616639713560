import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const formatHtmlToBlob = async (
  pdfRef: React.RefObject<HTMLDivElement>,
  documentName: string
) => {
  const canvas = await html2canvas(pdfRef.current);
  const imgData = canvas.toDataURL("image/jpeg", 0.6);

  const pdf = new jsPDF({
    orientation: "p",
    unit: "px",
    format: "a4",
  });

  // A4 page dimensions in pixels
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  // Calculate the scale ratio to fit the image width to the page width
  const imgWidth = canvas.width;
  const scale = pageWidth / imgWidth;
  const imgHeight = canvas.height * scale;

  // Initial height for the image displacement
  let yPos = 0;

  // While there's image left to add to the PDF
  while (yPos < imgHeight) {
    // If it's not the first page, add a new one
    if (yPos !== 0) {
      pdf.addPage();
    }

    // Calculate the height of the image section that fits into the current page
    const sectionHeight = Math.min(pageHeight, imgHeight - yPos);

    // Add the image section to the PDF
    // Note: 'yPos / scale' calculates the correct starting position in the original image
    pdf.addImage(imgData, "JPEG", 0, -yPos, pageWidth, imgHeight);

    // Increase yPos by the page height adjusted by the scaling for the next iteration
    yPos += sectionHeight;
  }

  // Use 'output' to get the PDF as a blob
  const pdfBlob = pdf.output("blob");

  //* NOTE: The following code is commented out as it's not necessary for the logic but it's useful for downloading purposes
  /* // Create a temporary URL for the blob
	const pdfUrl = URL.createObjectURL(pdfBlob);

	// Create an <a> element to download the PDF
	const downloadLink = document.createElement('a');
	downloadLink.href = pdfUrl;
	downloadLink.download = 'documento2.pdf'; // File name for download
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);

	// Cleanup: Revoke the URL of the created object to release resources
	URL.revokeObjectURL(pdfUrl); */

  const formData = new FormData();
  formData.append("Report", pdfBlob, documentName);

  // return null;
  return formData;
};
