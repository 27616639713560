import { getLoungeEmployees_service } from "@Services/lounges";
import { useSalesMovementsStore } from "@Stores/useSalesMovementsStore";
import { capitalizeWords } from "@Utilities/StringFormats";
import { IEmployee } from "@Models/interfaces/employees";

export const getLoungeEmployees = async () => {
  const { setEmployees, setIsLoadingEmployees, setError } =
    useSalesMovementsStore.getState();

  try {
    setIsLoadingEmployees(true);
    const response: IResponse = await getLoungeEmployees_service();

    if (!response.success) {
      setError(response.user_message || "Error al obtener empleados");
      return response;
    }

    const formattedResponse: IEmployee[] = response.data
      .map((employee: any) => {
        if (!employee.name) return null;
        return {
          id: employee.idEmployee,
          name: capitalizeWords(employee.name) || "",
          lastName: capitalizeWords(employee.lastName) || "",
          email: employee.email || "",
          phoneNumber: employee.phoneNumber || "",
          profilePicture: employee.profileImage,
          position: employee.typeOfPosition,
        };
      })
      .filter((employee: IEmployee | null) => employee !== null);

    setEmployees(formattedResponse);
    setError(null);
    return response;
  } catch (error) {
    setError("Error inesperado al obtener empleados");
    return {
      success: false,
      message: "Error inesperado al obtener empleados",
      data: null,
    };
  } finally {
    setIsLoadingEmployees(false);
  }
};
