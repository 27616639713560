import React, { useEffect } from "react";
import { getConceptsMovements } from "@Adapters/saleboxes/movements/getConceptsMovements.adapter";
import { useSalesMovementsStore } from "@Stores/useSalesMovementsStore";

const useConceptsMovements = () => {
  const { concepts, entryConcepts, outputConcepts } =
    useSalesMovementsStore.getState();

  useEffect(() => {
    (async () => {
      // Se obtienen los conceptos de movimiento
      await getConceptsMovements();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { concepts, entryConcepts, outputConcepts };
};

export default useConceptsMovements;
