import { create } from "zustand";

export interface IToasterStore {
  message: string;
  setMessage: (message: string) => void;
  status: number;
  setStatus: (status: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  toastTimeDuration: number | string;
  setToastTimeDuration: (toastTimeDuration: number | string) => void;
  setToaster: (
    message: string,
    status: number,
    loading?: boolean,
    toastTimeDuration?: number | string
  ) => void;
  setEmpty: () => void;
}

export const useToasterStore = create((set) => ({
  message: "",
  setMessage: (message: string) => set({ message }),
  status: 0,
  setStatus: (status: number) => set({ status }),
  loading: false,
  setLoading: (loading: boolean) => set({ loading }),
  toastTimeDuration: 0,
  setToastTimeDuration: (toastTimeDuration: number | string) =>
    set({ toastTimeDuration }),
  setToaster: (
    message: string,
    status: number,
    loading?: boolean,
    toastTimeDuration?: number | string
  ) => {
    set({ message, status, loading, toastTimeDuration });
  },
  setEmpty: () =>
    set({ message: "", status: 0, loading: false, toastTimeDuration: 0 }),
}));
