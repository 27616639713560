import { create } from "zustand";
import { persist } from "zustand/middleware";

interface FetchResponse {
  isSuccessful: boolean;
  status: null | number;
  message: string;
  data: any;
}

interface Movements {
  data: any;
  currentPage: number;
  totalPages: number;
}

interface SaleBoxReportState {
  fetchResponse: FetchResponse;
  movements: Movements;
  report: any;
  fromDate: string;
  toDate: string;
  salebox: string;
  updateReport: (response: FetchResponse) => void;
  updateReportMovements: (movements: Movements) => void;
  updateReportFilters: (event: {
    target: { name: string; value: string };
  }) => void;
}

export const useSaleBoxReportStore = create<SaleBoxReportState>()(
  persist(
    (set) => ({
      fetchResponse: {
        isSuccessful: false,
        status: null,
        message: "",
        data: null,
      },
      movements: {
        data: null,
        currentPage: 1,
        totalPages: 1,
      },
      report: null,
      fromDate: "",
      toDate: "",
      salebox: "",

      updateReport: (response) =>
        set(() => ({
          fetchResponse: response,
          report: response.data,
        })),

      updateReportMovements: (movements) => set(() => ({ movements })),

      updateReportFilters: (event) =>
        set((state) => ({
          [event.target.name]: event.target.value,
        })),
    }),
    {
      name: "saleBoxReport-storage",
    }
  )
);
