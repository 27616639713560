import { create } from "zustand";

export interface ILoungesStore {
  lounges: ILounge[];
  setLounges: (lounges: ILounge[]) => void;
  loungesFetchResponse: IFetchReportResponse;
  setLoungesFetchResponse: (response: IFetchReportResponse) => void;
}

export const useLoungesStore = create((set) => ({
  lounges: [],
  setLounges: (lounges: ILounge[]) => set({ lounges }),
  loungesFetchResponse: {
    status: "loading",
    message: "",
  },
  setLoungesFetchResponse: (response: IFetchReportResponse) =>
    set({ loungesFetchResponse: response }),
}));
