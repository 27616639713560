const GW_API = "https://gw-qa.prikly.io";
export const reportsBaseUrl = `${GW_API}/reportes/v1`;
export const warehousesBaseUrl = `${GW_API}/almacen/v1`;
export const loungesBaseUrl = `${GW_API}/lounges/v1`;
export const servicesBaseUrl = `${GW_API}/servicios/v1`;
export const employeesBaseUrl = `${GW_API}/empleados/v1`;
export const saleboxesBaseUrl = `${GW_API}/caja/v1`;
export const oldBaseUrl = `${GW_API}/viuti2/v1`;

const tokenLocal = localStorage.getItem("access_token");

export const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenLocal}`,
  },
};
