import React, { useState } from "react";
import style from "./ProductReportFilter.module.css";
// @ts-ignore
import { SelectInput, DateInput, ButtonWithoutIcon } from "@viuti/recursos";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import {
  IProductsReportStore,
  useProductsReportStore,
} from "@Stores/useProductsReportStore";

const ProductReportFilter = ({ loadReportAction }) => {
  const { lounges } = useLoungesStore() as ILoungesStore;
  const { filter, setFilter } =
    useProductsReportStore() as IProductsReportStore;
  const { loungeId, fromDate, toDate } = filter;

  const [isSubmitPending, setIsSubmitPending] = useState(false);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFilter = { ...filter, [name]: value };
    setFilter(newFilter);
  };

  const onSubmit = async () => {
    setIsSubmitPending(true);
    await loadReportAction();
    setIsSubmitPending(false);
  };

  return (
    <div className={style.filter__container}>
      <span className={style.filter__salebox}>
        <SelectInput
          label="Local"
          value={loungeId}
          items={lounges}
          handleChange={handleValueChange}
          name="loungeId"
        />
      </span>
      <span className={style.filter__dates}>
        <span className={style.filter__dates__input}>
          <DateInput
            label="Desde"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: fromDate,
              onChange: handleValueChange,
              name: "fromDate",
            }}
          />
        </span>
        <span className={style.filter__dates__input}>
          <DateInput
            label="Hasta"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: toDate,
              onChange: handleValueChange,
              name: "toDate",
            }}
          />
        </span>
        <ButtonWithoutIcon
          isPrimary={false}
          textBttn={"Cargar"}
          handleClick={onSubmit}
          disabled={!loungeId || !fromDate || !toDate || isSubmitPending}
          isLoading={isSubmitPending}
        />
      </span>
    </div>
  );
};

export default ProductReportFilter;
