export const getTimeOptions = () => {
  const timeOptions = [];
  for (let hour = 0; hour <= 12; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = hour + minute / 60;
      const formattedTime = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")} ${hour < 12 ? "AM" : "PM"}`;

      // @ts-ignore
      timeOptions.push({ value: time.toString(), name: formattedTime });
    }
  }

  return timeOptions;
};
