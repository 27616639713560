// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f8MLN9g0FjjofusLEOTc {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}

.MMh_VKW6Z71bds2rjuf5 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yj9_FssSDoa3C4lGSTVB {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.OiLK_hxz_3EL2x1J46Gu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 1.25rem 2rem;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  transition: border-color 50ms ease-in-out, background-color 150ms ease-in-out;

  &:hover {
    border-color: #8f85b9;
    background-color: #ede8ff;
  }
}

.Epkbk7FBXM1Qx6z34GeL {
  font: 800 1rem "Mulish", sans-serif;
  color: #937cf4;
}

.l4R8eeXuN8OoSprBn_Q7 {
  font: 400 0.8rem "Mulish", sans-serif;
  color: #1a0063;
}
`, "",{"version":3,"sources":["webpack://./src/pages/InitialView/views/ReportsList/ReportsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,6EAA6E;;EAE7E;IACE,qBAAqB;IACrB,yBAAyB;EAC3B;AACF;;AAEA;EACE,mCAAmC;EACnC,cAAc;AAChB;;AAEA;EACE,qCAAqC;EACrC,cAAc;AAChB","sourcesContent":[".view__container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 24px;\n}\n\n.search_container {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.report_list {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  gap: 16px;\n}\n\n.report_element {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  width: 100%;\n  padding: 1.25rem 2rem;\n  border-radius: 8px;\n  background-color: #ffffff;\n  border: 1px solid #e0e0e0;\n  transition: border-color 50ms ease-in-out, background-color 150ms ease-in-out;\n\n  &:hover {\n    border-color: #8f85b9;\n    background-color: #ede8ff;\n  }\n}\n\n.report_element_title {\n  font: 800 1rem \"Mulish\", sans-serif;\n  color: #937cf4;\n}\n\n.report_element_description {\n  font: 400 0.8rem \"Mulish\", sans-serif;\n  color: #1a0063;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view__container": `f8MLN9g0FjjofusLEOTc`,
	"search_container": `MMh_VKW6Z71bds2rjuf5`,
	"report_list": `yj9_FssSDoa3C4lGSTVB`,
	"report_element": `OiLK_hxz_3EL2x1J46Gu`,
	"report_element_title": `Epkbk7FBXM1Qx6z34GeL`,
	"report_element_description": `l4R8eeXuN8OoSprBn_Q7`
};
export default ___CSS_LOADER_EXPORT___;
