import React, { useContext, useEffect, useState } from "react";
import { LoadingPulseIcon } from "../../models/icons";
import style from "./InitialView.module.css";
// @ts-ignore
import { CriticalErrorPage } from "@viuti/recursos";
import { getAvailableReports } from "./_mocks/getAvailableReports";
import { getLounges } from "@Adapters/getLounges.adapter";
import ReportsList from "./views/ReportsList/ReportsList";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";

export const InitialView = () => {
  const { loungesFetchResponse, setLoungesFetchResponse, setLounges } =
    useLoungesStore() as ILoungesStore;
  const { setToaster } = useToasterStore() as IToasterStore;

  const reports = getAvailableReports;

  useEffect(() => {
    if (loungesFetchResponse.status !== "success")
      (async () => {
        setLoungesFetchResponse({
          status: "loading",
          message: null,
        });
        const response = await getLounges(setToaster);
        setLounges(response.data);
        setLoungesFetchResponse({
          status: response.success ? "success" : "error",
          message: response.user_message,
        });
      })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loungesFetchResponse.status === "error") return <CriticalErrorPage />;

  return (
    <div id="viuti-front-mainContent">
      {loungesFetchResponse.status === "loading" ? (
        <div className={style.loader_container}>
          <img
            className={style.loader_icon}
            src={LoadingPulseIcon}
            alt="AnimatedLoader"
          />
        </div>
      ) : (
        <ReportsList reports={reports} />
      )}
    </div>
  );
};
