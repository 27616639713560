import React, { useContext, useEffect, useState } from "react";
import style from "./ProgramReportModal.module.css";

import {
  DateInput,
  SelectInput,
  SearchWithColoredBorder,
  // @ts-ignore
} from "@viuti/recursos";
import { getAvailableReports } from "@Pages/InitialView/_mocks/getAvailableReports";
import { handleInputChange } from "./functions/handleInputChange";
import { getTimeOptions } from "./functions/getTimeOptions";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";

const ProgramReportModal = () => {
  const { handleButtonTwoChange } = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  const [values, setValues] = useState({
    report: null,
    lounge: "",
    reportFromDate: null,
    reportToDate: null,
    sendDate: null,
    sendTime: "",
  });

  const [searchInput, setSearchInput] = useState("");
  const reports = getAvailableReports;
  const [searchedResults, setSearchedResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(true);

  const searchResults = (search: string) => {
    setSearchInput(search);
    const reports = getAvailableReports;
    const filteredReports = reports.filter((report) =>
      report.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setSearchedResults(filteredReports);
  };

  const renderResults: IReport[] =
    searchInput === "" ? reports : searchedResults;

  const handleSelectReport = (report: IReport) => {
    setSearchInput(report.name);
    setValues({ ...values, report });
    setShowSearchResults(false);
  };

  const handleSearchBlur = (e) => {
    setShowSearchResults(false);
    const valueExistsOnList = renderResults.find(
      (search) => search.name === searchInput
    );
    if (!valueExistsOnList) {
      setSearchInput(values.report?.name);
    }
  };

  useEffect(() => {
    const hasEmptyValues = Object.values(values).some(
      (value) => value === null || value === ""
    );
    if (!hasEmptyValues) {
      handleButtonTwoChange((prev) => {
        return {
          ...prev,
          isDisabled: false,
        };
      });
    } else {
      handleButtonTwoChange((prev) => {
        return {
          ...prev,
          isDisabled: true,
        };
      });
    }
  }, [values, handleButtonTwoChange]);

  return (
    <form className={style.form} onSubmit={(e) => e.preventDefault()}>
      <div className={style.form_row}>
        <span className={style.form_row_input_container}>
          <SearchWithColoredBorder
            label="Tipo de reporte"
            value={searchInput}
            placeholder={"Buscar reporte"}
            setValue={searchResults}
            allData={renderResults}
            onClickResult={(report) => handleSelectReport(report)}
            showResults={showSearchResults}
            searchBy={["name"]}
            clearInput={false}
            inputProps={{
              onFocus: () => setShowSearchResults(true),
              onBlur: (e) => handleSearchBlur(e),
            }}
            required
          />
        </span>
        <span className={style.form_row_input_container}>
          {/* <SelectInput
            label="Local"
            value={values.lounge}
            handleChange={(e) => handleInputChange(e, setValues)}
            name={"lounge"}
            items={lounges}
            required
          /> */}
        </span>
      </div>
      <p className={style.form_row_label}>
        Selecciona el rango de fechas para tu reporte:
      </p>
      <div className={style.form_row}>
        <span className={style.form_row_input_container}>
          <DateInput
            label="Desde"
            inputProps={{
              name: "reportFromDate",
              value: values.reportFromDate,
              onChange: (e) => handleInputChange(e, setValues),
            }}
            required
          />
        </span>
        <span className={style.form_row_input_container}>
          <DateInput
            label="Hasta"
            inputProps={{
              name: "reportToDate",
              value: values.reportToDate,
              onChange: (e) => handleInputChange(e, setValues),
            }}
            required
          />
        </span>
      </div>
      <p className={style.form_row_label}>Selecciona fecha y hora de envio:</p>
      <div className={style.form_row}>
        <span className={style.form_row_input_container}>
          <DateInput
            label="Fecha de envío"
            inputProps={{
              name: "sendDate",
              value: values.sendDate,
              onChange: (e) => handleInputChange(e, setValues),
            }}
            required
          />
        </span>
        <span className={style.form_row_input_container}>
          <SelectInput
            label="Hora de envío"
            value={values.sendTime}
            handleChange={(e) => handleInputChange(e, setValues)}
            name={"sendTime"}
            items={getTimeOptions()}
            required
          />
        </span>
      </div>
    </form>
  );
};

export default ProgramReportModal;
