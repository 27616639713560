import { getClientsCategories_service } from "@Services/clients";

export const getClientsCategories = async (
  setToaster: (
    message: string,
    status: number,
    loading?: boolean,
    toastTimeDuration?: number | string
  ) => void
) => {
  const response: IResponse = await getClientsCategories_service();

  if (!response.success) {
    setToaster(response.user_message, response.status, false, 4000);
    return response;
  }

  const formattedResponse = response.data.map((category: any) => {
    return {
      value: category.id,
      name: category.name,
    };
  });

  return { ...response, data: formattedResponse };
};
