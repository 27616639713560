// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._rI1Qadquk5J_r1vJlKL {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.xoBwebxMV9uByunMnTFg {
  width: 100%;
  text-align: left;
  color: #392b75;
  font: 600 0.8rem/1.2rem "Mulish", sans-serif;
}

.B61mNpmAyr2pyvxoTSlB {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.8rem;
}

.tZk330OBHGn7QPlnTMBg {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
}

.NrteD9QzZnKgrW6Fn5Om {
  width: 100%;
  min-width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/SendReportModal/SendReportModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".form {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 1rem;\n}\n\n.form_row_label {\n  width: 100%;\n  text-align: left;\n  color: #392b75;\n  font: 600 0.8rem/1.2rem \"Mulish\", sans-serif;\n}\n\n.form_row {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  gap: 0.8rem;\n}\n\n.form_column {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 0.8rem;\n}\n\n.form_row_input_container {\n  width: 100%;\n  min-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `_rI1Qadquk5J_r1vJlKL`,
	"form_row_label": `xoBwebxMV9uByunMnTFg`,
	"form_row": `B61mNpmAyr2pyvxoTSlB`,
	"form_column": `tZk330OBHGn7QPlnTMBg`,
	"form_row_input_container": `NrteD9QzZnKgrW6Fn5Om`
};
export default ___CSS_LOADER_EXPORT___;
