import React, { useContext, useState } from "react";
import style from "./ProductsReport.module.css";
// @ts-ignore
import { HeaderNavigation, LoadingScreen } from "@viuti/recursos";
import { navigateTo } from "@Utilities/navigate";
import { useNavigate } from "react-router-dom";
import PieGraphBuilder from "@Components/ui/molecules/PieGraphBuilder/PieGraphBuilder";
import ProductReportFilter from "@Components/ui/molecules/ProductReportFilter/ProductReportFilter";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import {
  useProductsReportStore,
  IProductsReportStore,
} from "@Stores/useProductsReportStore";
import TopProductsList from "@Components/ui/molecules/TopProductsList/TopProductsList";
import useLoadProductReport from "@Hooks/useLoadProductReport";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import { PRODUCT_REPORT_NAME } from "@Models/consts";
import { changeModalExport } from "@Hooks/changeModalExport";
import ProductsTable from "@Components/ui/organisms/ProductsTable/ProductsTable";
import useExportProductsReport from "@Components/ui/organisms/ExportProductsReportModal/hooks/useExportProductsReport";
import { FILE_EXTENSION } from "@Models/interfaces/reports";

const ProductsReport = () => {
  const { loadProductReport } = useLoadProductReport();
  const navigate = useNavigate();
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  const { loungesFetchResponse } = useLoungesStore() as ILoungesStore;
  const { productsFetchResponse, piegraphProductsData, topProducts } =
    useProductsReportStore() as IProductsReportStore;

  const [highlightedTopProduct, setHighlightedTopProduct] = useState(null);

  const handleArcClick = (item) => {
    if (item === null) return setHighlightedTopProduct(null);
    if (item?.dataIndex !== null) {
      const highlightedProduct = topProducts[item?.dataIndex];
      setHighlightedTopProduct(highlightedProduct);
    }
  };

  const { onSubmit, fetchResponses } = useExportProductsReport({
    modalContext,
  });

  return (
    <div id="viuti-front-mainContent">
      <div className={style.view__container}>
        <HeaderNavigation
          title={"Reporte de productos"}
          previousAction={() => navigateTo(navigate, "/")}
          previousActionMovement={"back"}
          buttonProps={{
            textBttn: "Exportar CSV",
            handleClick: () => onSubmit({ shareType: FILE_EXTENSION.CSV }),
            isDisabled: fetchResponses[FILE_EXTENSION.CSV]?.loading,
            isLoading: fetchResponses[FILE_EXTENSION.CSV]?.loading,
            isPrimary: true,
            isHidden: false,
          }}
        />
        {loungesFetchResponse.status === "loading" ||
        productsFetchResponse.status === "loading" ? (
          <LoadingScreen visible={true} />
        ) : (
          <>
            <h3 className={style.view__instructions}>
              Visualiza y analiza los datos de ventas de productos para mejorar
              la toma de decisiones en tu negocio. Todos los precios mostrados
              incluyen sus impuestos correspondientes.
            </h3>
            <div className={style.view__content}>
              <ProductReportFilter loadReportAction={loadProductReport} />
              <div className={style.report_container}>
                <div
                  className={`${style.card__container} ${style.pie_graph__container}`}
                >
                  <h4 className={style.title}>Productos mas vendidos</h4>
                  <div className={style.graph__container}>
                    <PieGraphBuilder
                      data={piegraphProductsData}
                      onArcClick={handleArcClick}
                    />
                    <TopProductsList
                      highlightedProduct={highlightedTopProduct}
                    />
                  </div>
                </div>
                <div
                  className={`${style.card__container} ${style.pie_graph__container}`}
                >
                  <h4 className={style.title}>Mis productos</h4>
                  <ProductsTable />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsReport;
