import React from "react";
import style from "./changeModalType.module.css";

import { ReportModalStateContextProps } from "@Hooks/ReportModalStateContext";

import ProgramReportModal from "@Components/ui/organisms/ProgramReportModal/ProgramReportModal";
import SendReportModal from "@Components/ui/organisms/SendReportModal/SendReportModal";

export const changeModalType = (
  report: IReport,
  modalContext: ReportModalStateContextProps
) => {
  const {
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleNoSizeLimitChange,
  } = modalContext;

  const isProgrammingReport = report === null;

  const handleProgramReport = async () => {
    // return await postOpenSaleBox(initialCash, selectedSaleBox, dispatch);
  };

  const handleSendReport = async () => {
    // return await postCloseSaleBox(lastCash, selectedSaleBox, dispatch);
  };

  const ModalChildren = isProgrammingReport ? (
    <ProgramReportModal />
  ) : (
    <SendReportModal report={report} />
  );

  handleTitleChange(
    <span className={style.header_title_container}>
      {isProgrammingReport ? "Programar reporte" : `Reporte de ${report.name}`}
    </span>
  );
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonOne: false,
    showButtonTwo: true,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() =>
    handleVisibilityChange({
      isVisible: false,
      setIsVisible: () => {},
    })
  );
  handleNoSizeLimitChange(true);
  handleButtonTwoChange({
    textButtonTwo: isProgrammingReport
      ? "Programar envío"
      : "Recibir reporte en mi correo",
    actionButtonTwo: () =>
      isProgrammingReport ? handleProgramReport() : handleSendReport(),
    isDisabled: true,
    isLoading: false,
  });
};
