import axios from "axios";

import { CATEGORIES_MOCKS, CLIENTS_MOCKS } from "../_mocks_/clients";
import { config, reportsBaseUrl, servicesBaseUrl } from "./constGlobals";

export const getClients_service = async ({
  loungeId,
  categoryId,
  dateFilterType,
  fromDate,
  toDate,
  page,
}) => {
  const url = `${reportsBaseUrl}/clients/report`;
  try {
    const response: IResponse = await axios
      .get(url, {
        ...config,
        params: {
          loungeId,
          categoryId,
          dateFilterType,
          fromDate,
          toDate,
          page,
        },
      })
      .then((res) => res.data);
    return response;
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se ha podido conseguir los reportes de clientes.",
      developer_message: null,
      success: false,
      data: null,
    };
  }
};

export const getClientsCategories_service = async () => {
  const response = {
    success: true,
    status: 200,
    user_message: "Categorías obtenidas correctamente.",
    developer_message: "Categorías obtenidas correctamente.",
    data: CATEGORIES_MOCKS,
  };

  return response;
  /* const url = `${servicesBaseUrl}/Services/GetServices`;

	try {
		const response: IResponse = await axios
			.get(url, config)
			.then((res) => res.data);
		return {
			isSuccess: true,
			...response,
		};
	} catch (error) {
		return {
			isSuccess: false,
			status: error?.response?.status || 500,
			message:
				error?.response?.data?.message ||
				'No se han podido conseguir los servicios del local.',
			data: null,
		};
	} */
};

export const exportClientReport_service = async (loungeId: number) => {
  const url = `${reportsBaseUrl}/clients/export`;
  try {
    const response: IResponse = await axios.get(url, {
      ...config,
      params: { loungeId },
    });
    return response.data;
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se ha podido exportar el reporte de clientes.",
      developer_message: null,
      success: false,
      data: null,
    };
  }
};
