import React, { useEffect, useRef } from "react";
import styles from "./ClientsTable.module.css";
import CustomTable, {
  Column,
} from "@Components/ui/molecules/CustomTable/CustomTable";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { IClient } from "@Models/interfaces/clients";
import {
  IClientsReportStore,
  useClientsReportStore,
} from "@Stores/useClientsReportStore";
import useLoadClientReport from "@Hooks/useLoadClientReport";

interface IClientsTable {
  clients: IClient[];
}

const ClientsTable: React.FC<IClientsTable> = ({ clients }) => {
  const { loadClientReport } = useLoadClientReport();
  const { currentPage, setCurrentPage, pages } =
    useClientsReportStore() as IClientsReportStore;
  const prevPageRef = useRef(currentPage);
  const columns: Column<IClient>[] = [
    {
      header: "Nombre",
      accessor: (client: IClient) => {
        if (client.id <= 0 || !client.id) return client.name;
        return (
          <a
            href={`/clientes/detalles/${client.id}`}
            target="_blank"
            className={styles.link}
          >
            {client.name}
          </a>
        );
      },
    },
    {
      header: "Categoría",
      accessor: (client: IClient) => client.category?.name || "No categorizado",
    },
    {
      header: "Cumpleaños",
      accessor: (client: IClient) => client.birthday || "Desconocido",
    },
    { header: "Visitas", accessor: "visitCount" as keyof IClient },
    { header: "Última visita", accessor: "lastVisitDate" as keyof IClient },
    {
      header: "Total de compras",
      accessor: (client: IClient) => formatToSoles(client.totalSpent),
    },
  ];

  useEffect(() => {
    if (prevPageRef.current !== currentPage) {
      loadClientReport();
      prevPageRef.current = currentPage;
    }
  }, [currentPage, loadClientReport]);

  return (
    <CustomTable
      data={clients}
      columns={columns}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={pages}
    />
  );
};

export default ClientsTable;
