import { STATUS_OK } from "@Models/httpsStatus";
import { getSalesDocuments_service } from "@Services/saledocuments";
import { useSaleDocumentsStore } from "@Stores/useSaleDocumentsStore";
import { useToasterStore, IToasterStore } from "@Stores/useToasterStore";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { capitalizeWords } from "@Utilities/StringFormats";
import { format } from "date-fns";

interface Form {
  searParameter: string;
  idBox: string;
  typeDocumetnId: string;
  status: string;
  statusFE: string;
  startDate: string;
  endDate: string;
}

export const getSalesDocuments = async (form: Form, page: number) => {
  const { setToaster } = useToasterStore.getState() as IToasterStore;
  const { setSaleDocuments, setTotalPages } = useSaleDocumentsStore.getState();
  const {
    searParameter,
    idBox,
    typeDocumetnId,
    status,
    statusFE,
    startDate,
    endDate,
  } = form;

  const payload = {
    searchParameter: searParameter,
    idBox: idBox === "Todas" ? "" : idBox,
    typeDocumentId: typeDocumetnId === "Todos" ? "" : typeDocumetnId,
    status: status === "Todos" ? "" : status,
    statusFE: statusFE === "Todos" ? "" : statusFE,
    startDate: startDate,
    endDate: endDate,
    page: page,
  };

  const response: IResponse = await getSalesDocuments_service(payload);

  if (!response.success) {
    setToaster(response.user_message, response.status, true);
    return response;
  }

  const salesDocuments = response.data.documents.map((saleDocument) => {
    return {
      saleBoxId: saleDocument.saleBoxId,
      box: saleDocument.saleBoxName,
      type: formatTypeDocument(saleDocument.typeDocumentName),
      number: saleDocument.number,
      date: convertDateFormat(saleDocument.dateEmision),
      justTheDate: convertDateFormatTimeOrDate(
        saleDocument.dateEmision,
        "date"
      ),
      time: convertDateFormatTimeOrDate(saleDocument.dateEmision, "time"),
      employee: capitalizeWords(saleDocument.employeeName),
      client: capitalizeWords(saleDocument.clientName),
      clientDocumentName: saleDocument.clientDocumentName,
      clientDocumentNumber: saleDocument.clientDocumentNumber,
      clientId: saleDocument.clientId,
      total: formatToSoles(saleDocument.total),
      status: capitalizeWords(saleDocument.statusName),
      statusFE:
        capitalizeWords(saleDocument.statusFEName) === "Anulado (baja)"
          ? "Anulado"
          : capitalizeWords(saleDocument.statusFEName),
      id: saleDocument.saleDocumentId,
      series: saleDocument.serie,
      icbper: formatToSoles(saleDocument.icbper),
      subTotal: formatToSoles(saleDocument.subTotal),
      igv: formatToSoles(saleDocument.igv),
      discount: formatToSoles(saleDocument.discount),
      typeDocument: saleDocument.typeDocument,
    };
  });

  setSaleDocuments(salesDocuments);
  setTotalPages(response.data.maxPages);

  return {
    status: STATUS_OK,
    message: "Documentos de venta obtenidos correctamente",
    data: {
      salesDocuments: salesDocuments,
      pages: response.data.maxPages,
    },
  };
};

const convertDateFormatTimeOrDate = (date: string, type: string) => {
  const dateArray = convertDateFormat(date).split(" ");
  if (type === "date") {
    return dateArray[0];
  }
  return dateArray[1] + " " + dateArray[2];
};

const formatTypeDocument = (document) => {
  switch (document.toLowerCase()) {
    case "boleta de venta":
      return "Boleta de venta";
    case "factura":
      return "Factura";
    case "nota de credito":
      return "Nota de crédito";
    case "nota de venta":
      return "Nota de venta";
    default:
      return " - ";
  }
};

const convertDateFormat = (date: string): string => {
  // Manually parse the date and time string
  const parts = date.split(" ");
  const dateParts = parts[0].split("/");
  const timeParts = parts[1].split(":");
  const month = parseInt(dateParts[0]) - 1; // JavaScript months are 0-indexed
  const day = parseInt(dateParts[1]);
  const year = parseInt(dateParts[2]);
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);

  // Create a Date object
  const formattedDate = new Date(year, month, day, hours, minutes, seconds);

  // Format the date into the new format
  const formattedDateString = format(formattedDate, "dd/MM/yyyy hh:mm aa");

  return formattedDateString;
};
