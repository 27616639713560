import { downloadCashMovements_service } from "@Services/movements";
import { useToasterStore, IToasterStore } from "@Stores/useToasterStore";

export const getBase64donwloadMovements = async (payload) => {
  const { setToaster } = useToasterStore.getState() as IToasterStore;
  const obj = {
    searchParameter:
      payload.searchParameter === undefined ? "" : payload.searchParameter,
    idBox: payload.idBox === "Todas" ? "" : payload.idBox,
    type: payload.type === undefined ? "" : payload.type,
    concept: payload.concept === "" ? "" : payload.concept,
    startDate: payload.startDate === "" ? "" : payload.startDate,
    endDate: payload.endDate === "" ? "" : payload.endDate,
  };

  const response = await downloadCashMovements_service(obj);
  if (!response.isSuccessful) {
    return setToaster(response.message, response.status, true);
  }

  return response;
};
