import React, { useState, useRef, useEffect } from "react";
import style from "./ClientReportFilter.module.css";
// @ts-ignore
import { SelectInput, DateInput, ButtonWithoutIcon } from "@viuti/recursos";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import {
  IClientsReportStore,
  useClientsReportStore,
} from "@Stores/useClientsReportStore";

const ClientReportFilter = ({ loadReportAction }) => {
  const { lounges } = useLoungesStore() as ILoungesStore;
  const { filter, setFilter, clientsCategories } =
    useClientsReportStore() as IClientsReportStore;
  const { loungeId, categoryId, fromDate, toDate, dateFilterType } = filter;

  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const dateMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateMenuRef.current && !dateMenuRef.current.contains(event.target)) {
        setIsDateMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const newFilter = { ...filter, [name]: value };
    setFilter(newFilter);
  };

  const onSubmit = async () => {
    setIsSubmitPending(true);
    await loadReportAction();
    setIsSubmitPending(false);
  };

  const dateFilterOptions = [
    { value: 1, label: "Creación de clientes" },
    { value: 2, label: "Compras del cliente" },
    { value: 3, label: "Última visita del cliente" },
  ];

  const toggleDateMenu = () => setIsDateMenuOpen(!isDateMenuOpen);

  return (
    <div className={style.filter__container}>
      <div className={style.filter__row}>
        <span className={style.filter__dropdown}>
          <SelectInput
            label="Local"
            value={loungeId}
            items={lounges}
            handleChange={handleValueChange}
            name="loungeId"
          />
        </span>
        {/* <span className={style.filter__dropdown}>
					<SelectInput
						label="Categoría"
						value={categoryId}
						items={clientsCategories}
						handleChange={handleValueChange}
						name="categoryId"
					/>
				</span> */}
        <span className={style.filter__dropdown}>
          <ButtonWithoutIcon
            isPrimary={false}
            textBttn={"Filtrar por fechas"}
            handleClick={toggleDateMenu}
          />
          {isDateMenuOpen && (
            <div className={style.date_menu} ref={dateMenuRef}>
              {dateFilterOptions.map((option) => (
                <label key={option.value} className={style.radio_option}>
                  <input
                    type="radio"
                    name="dateFilterType"
                    value={option.value}
                    checked={dateFilterType == option.value}
                    onChange={handleValueChange}
                  />
                  {option.label}
                </label>
              ))}
              <div className={style.filter__dates__inputs}>
                <span className={style.filter__dates__input}>
                  <DateInput
                    label="Desde"
                    inputProps={{
                      placeholder: "Seleccionar fecha",
                      value: fromDate,
                      onChange: handleValueChange,
                      name: "fromDate",
                    }}
                  />
                </span>
                <span className={style.filter__dates__input}>
                  <DateInput
                    label="Hasta"
                    inputProps={{
                      placeholder: "Seleccionar fecha",
                      value: toDate,
                      onChange: handleValueChange,
                      name: "toDate",
                    }}
                  />
                </span>
              </div>
            </div>
          )}
        </span>
      </div>
      <div className={style.filter__row}>
        <ButtonWithoutIcon
          isPrimary={true}
          textBttn={"Cargar"}
          handleClick={onSubmit}
          disabled={!loungeId || !fromDate || !toDate || isSubmitPending}
          isLoading={isSubmitPending}
        />
      </div>
    </div>
  );
};

export default ClientReportFilter;
