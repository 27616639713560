import { ISaleBox } from "@Models/interfaces/saleboxes";
import { create } from "zustand";

interface ISaleBoxesStore {
  // Estado
  saleBoxes: ISaleBox[];
  selectedSaleBox: ISaleBox | null;
  isLoadingSaleBoxes: boolean;
  error: string | null;

  // Acciones para cajas
  setSaleBoxes: (boxes: ISaleBox[]) => void;
  addSaleBox: (box: ISaleBox) => void;
  updateSaleBox: (boxId: number, updatedBox: Partial<ISaleBox>) => void;
  removeSaleBox: (boxId: number) => void;
  setSelectedSaleBox: (box: ISaleBox | null) => void;

  // Acciones de estado
  setIsLoadingSaleBoxes: (isLoading: boolean) => void;
  setError: (error: string | null) => void;
  clearSaleBoxes: () => void;
}

const initialState = {
  saleBoxes: [],
  selectedSaleBox: null,
  isLoadingSaleBoxes: false,
  error: null,
};

export const useSaleBoxesStore = create<ISaleBoxesStore>((set) => ({
  ...initialState,

  // Acciones para cajas
  setSaleBoxes: (boxes) =>
    set({
      saleBoxes: boxes.map((box) => ({
        ...box,
        value: box.id.toString(), // Aseguramos que value sea string
      })),
    }),

  addSaleBox: (newBox) =>
    set((state) => ({
      saleBoxes: [
        ...state.saleBoxes,
        {
          ...newBox,
          value: newBox.id.toString(),
        },
      ],
    })),

  updateSaleBox: (boxId, updatedBox) =>
    set((state) => ({
      saleBoxes: state.saleBoxes.map((box) =>
        box.id === boxId
          ? {
              ...box,
              ...updatedBox,
              value: (updatedBox.id || box.id).toString(),
            }
          : box
      ),
    })),

  removeSaleBox: (boxId) =>
    set((state) => ({
      saleBoxes: state.saleBoxes.filter((box) => box.id !== boxId),
      selectedSaleBox:
        state.selectedSaleBox?.id === boxId ? null : state.selectedSaleBox,
    })),

  setSelectedSaleBox: (box) => set({ selectedSaleBox: box }),

  // Acciones de estado
  setIsLoadingSaleBoxes: (isLoading) => set({ isLoadingSaleBoxes: isLoading }),
  setError: (error) => set({ error }),
  clearSaleBoxes: () =>
    set({
      saleBoxes: [],
      selectedSaleBox: null,
      error: null,
    }),
}));
