// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oBR2Gf6bLLt4WVOhEags {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  overflow-y: auto;
  max-height: 310px;

  @media screen and (width <= 390px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.RhO1pUfNJzBkd96OHKAi {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 4px;
  gap: 0.875rem;
  transition: all 150ms ease-in-out;

  &:hover {
    background-color: rgb(240, 240, 240);
  }
}

.sWQISjaYkfQcdn2SiFkC {
  border: 1px solid #947cf4;
  background-color: #947cf40f;
}

.Zl8rUb5XCewhug6Q_UHe {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  overflow: hidden;
}

.XXHbSPqroKjBat7VrKFV {
  font: 600 0.75rem "Mulish", sans-serif;
  color: rgb(132, 132, 132);
}

.vFfRQYpvMOnbBMNQVzg1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.x4WJiV86p1bzQTge05PN {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #45348e;
}

.yrU5Oqmez9JTLXd99kza {
  display: flex;
  justify-content: flex-end;
  min-width: 85px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/TopProductsList/TopProductsList.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,gBAAgB;EAChB,iBAAiB;;EAEjB;IACE,4DAA4D;EAC9D;AACF;;AAEA;EACE,aAAa;EACb,+BAA+B;EAC/B,8BAA8B;EAC9B,oBAAoB;EACpB,oCAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,iCAAiC;;EAEjC;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".products_list__container {\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  gap: 1rem;\n  overflow-y: auto;\n  max-height: 310px;\n\n  @media screen and (width <= 390px) {\n    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  }\n}\n\n.product__button {\n  display: grid;\n  grid-template-columns: 1fr auto;\n  justify-content: space-between;\n  padding: 0.6rem 1rem;\n  border: 1px solid rgb(220, 220, 220);\n  border-radius: 4px;\n  gap: 0.875rem;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    background-color: rgb(240, 240, 240);\n  }\n}\n\n.product__highlighted {\n  border: 1px solid #947cf4;\n  background-color: #947cf40f;\n}\n\n.product__identifier {\n  display: flex;\n  gap: 0.6rem;\n  align-items: center;\n  overflow: hidden;\n}\n\n.product_position {\n  font: 600 0.75rem \"Mulish\", sans-serif;\n  color: rgb(132, 132, 132);\n}\n\n.product_name {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.product_variants__container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 6px;\n  color: #45348e;\n}\n\n.product__revenue {\n  display: flex;\n  justify-content: flex-end;\n  min-width: 85px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"products_list__container": `oBR2Gf6bLLt4WVOhEags`,
	"product__button": `RhO1pUfNJzBkd96OHKAi`,
	"product__highlighted": `sWQISjaYkfQcdn2SiFkC`,
	"product__identifier": `Zl8rUb5XCewhug6Q_UHe`,
	"product_position": `XXHbSPqroKjBat7VrKFV`,
	"product_name": `vFfRQYpvMOnbBMNQVzg1`,
	"product_variants__container": `x4WJiV86p1bzQTge05PN`,
	"product__revenue": `yrU5Oqmez9JTLXd99kza`
};
export default ___CSS_LOADER_EXPORT___;
