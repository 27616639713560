import React, { useEffect, useRef } from "react";
import styles from "./ServicesTable.module.css";
import {
  IServicesReportStore,
  useServicesReportStore,
} from "@Stores/useServicesReportStore";
import { formatToSoles } from "@Utilities/FormatsHandler";
import CustomTable from "@Components/ui/molecules/CustomTable/CustomTable";
import useLoadServiceReport from "@Hooks/useLoadServiceReport";
import { IService } from "@Models/interfaces/services";

const ServicesTable: React.FC = () => {
  const { loadServiceReport } = useLoadServiceReport();
  const { services, currentPage, setCurrentPage, pages } =
    useServicesReportStore() as IServicesReportStore;
  const prevPageRef = useRef(currentPage);
  const columns = [
    { header: "Nombre", accessor: (service: IService) => service.name },
    {
      header: "Cantidad vendido",
      accessor: (service: IService) => service.quantity,
    },
    {
      header: "Monto total de venta",
      accessor: (service: IService) => formatToSoles(service.totalRevenue),
    },
  ];

  useEffect(() => {
    if (prevPageRef.current !== currentPage) {
      loadServiceReport();
      prevPageRef.current = currentPage;
    }
  }, [currentPage, loadServiceReport]);

  return (
    <CustomTable
      data={services}
      columns={columns}
      currentPage={currentPage}
      totalPages={pages}
      setCurrentPage={setCurrentPage}
      emptyMessage="No se han encontrado servicios con estos parámetros de búsqueda."
    />
  );
};

export default ServicesTable;
