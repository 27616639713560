import { create } from "zustand";

interface INotificationStore {
  message: string;
  status: 0 | 200 | 400 | 404 | 500 | 501 | 502 | string;
  toastTimeDuration: number | "INFINITE";
  updateNotification: (notification: {
    message: string;
    status: number | string;
    toastTimeDuration: number | "INFINITE";
  }) => void;
  clearNotification: () => void;
}

export const useNotificationStore = create<INotificationStore>((set) => ({
  message: "",
  status: 0,
  toastTimeDuration: 4000,

  updateNotification: (notification) =>
    set({
      message: notification.message,
      status: notification.status as 0 | 200 | 400 | 404 | 500 | 501 | 502,
      toastTimeDuration: notification.toastTimeDuration,
    }),

  clearNotification: () =>
    set({
      message: "",
      status: 0,
      toastTimeDuration: 4000,
    }),
}));
