export const formatToSoles = (
  number: number,
  includePrefix: boolean = true
) => {
  number = Number(number);

  if (number === null || number === undefined) {
    return "";
  }

  const formatter = new Intl.NumberFormat("es-PE", {
    style: "currency",
    currency: "PEN",
    currencyDisplay: includePrefix ? "symbol" : "code",
  });

  return includePrefix
    ? formatter.format(number)
    : formatter.format(number).replace("PEN ", "");
};
