import React, { useEffect, useRef } from "react";
import style from "./TopServicesList.module.css";
import { IService } from "@Models/interfaces/services";
import {
  IServicesReportStore,
  useServicesReportStore,
} from "@Stores/useServicesReportStore";
import { HiBeaker } from "react-icons/hi2";
// @ts-ignore
import { IconWrapper } from "@viuti/recursos";
import { formatToSoles } from "@Utilities/FormatsHandler";

interface ITopServicesList {
  highlightedService: IService;
}

const TopServicesList: React.FC<ITopServicesList> = ({
  highlightedService,
}) => {
  const { topServices } = useServicesReportStore() as IServicesReportStore;
  const listRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    if (highlightedService && listRef.current) {
      const highlightedIndex = topServices.findIndex(
        (service) => service.id === highlightedService.id
      );
      const highlightedItem = itemRefs.current[highlightedIndex];

      if (highlightedItem) {
        const listRect = listRef.current.getBoundingClientRect();
        const itemRect = highlightedItem.getBoundingClientRect();
        const offset = itemRect.top - listRect.top;

        listRef.current.scrollBy({
          top: offset - listRect.height / 2 + itemRect.height / 2,
          behavior: "smooth",
        });
      }
    }
  }, [highlightedService, topServices]);

  return (
    <div className={style.services_list__container} ref={listRef}>
      {topServices.map((service, index) => {
        const isHighlighted = highlightedService?.id === service.id;

        return (
          <button
            key={service.id}
            className={`${style.service__button} ${
              isHighlighted ? style.service__highlighted : ""
            }`}
            ref={(el) => (itemRefs.current[index] = el)}
          >
            <div className={style.service__identifier}>
              <span className={style.service_position}>#{index + 1}</span>
              <p className={style.service_name}>{service.name}</p>
              <span className={style.service_variants__container}>
                <IconWrapper icon={HiBeaker} />
                {service.variants.length}
              </span>
            </div>
            <div className={style.service__revenue}>
              {formatToSoles(service.totalRevenue)}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default TopServicesList;
