import axios from "axios";
import { employeesBaseUrl, loungesBaseUrl, config } from "./constGlobals";

export const getLounges_service = async () => {
  const url = `${loungesBaseUrl}/lounges/lounges`;

  try {
    const response = await axios.get(url, config).then((res) => res.data);
    return {
      success: true,
      status: response.status,
      user_message: response.message,
      developer_message: response.message,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se han podido encontrar los locales de la empresa.",
      developer_message: null,
      data: null,
    };
  }
};

export const getLoungeEmployees_service = async () => {
  const url = `${employeesBaseUrl}/Team/TeamMembers`;

  try {
    const response = await axios.get(url, config).then((res) => res.data);
    return {
      success: true,
      status: response.status,
      user_message: response.message,
      developer_message: response.message,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los empleados.",
      developer_message: null,
      data: [],
    };
  }
};
