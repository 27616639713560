import React, { useEffect } from "react";
import { getLounges } from "@Adapters/getLounges.adapter";
import { getServicesReport } from "@Adapters/getServicesReport.adapter";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import {
  IServicesReportStore,
  useServicesReportStore,
} from "@Stores/useServicesReportStore";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";

const useLoadServiceReport = () => {
  const { loungesFetchResponse, setLoungesFetchResponse, lounges, setLounges } =
    useLoungesStore() as ILoungesStore;
  const {
    filter,
    setFilter,
    currentPage,
    setPages,
    servicesFetchResponse,
    setServicesFetchResponse,
    setServices,
    setTopServices,
    setPiegraphServicesData,
  } = useServicesReportStore() as IServicesReportStore;
  const { setToaster } = useToasterStore() as IToasterStore;

  const loadServiceReport = async (tempFilter?) => {
    const localFilter = filter.loungeId ? filter : tempFilter;

    setServicesFetchResponse({
      status: "loading",
      message: null,
    });
    const payload = { ...localFilter, page: currentPage };
    const setters = {
      setToaster,
      setPages,
      setServices,
      setTopServices,
      setPiegraphServicesData,
      setServicesFetchResponse,
    };
    await getServicesReport(setters, payload);
  };

  const setInitialFilter = async (localLounges) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const formattedLastDate = `${currentDate.getFullYear() - 1}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const loungeId = localLounges[0]?.value;
    const newValues = {
      loungeId: loungeId || "",
      fromDate: formattedLastDate,
      toDate: formattedDate,
    };
    setFilter(newValues);

    return newValues;
  };

  useEffect(() => {
    if (loungesFetchResponse.status !== "success") {
      (async () => {
        setLoungesFetchResponse({
          status: "loading",
          message: null,
        });
        const response = await getLounges(setToaster);
        if (response.success) {
          setLounges(response.data);
          const initialFilter = await setInitialFilter(response.data);
          await loadServiceReport(initialFilter);
        }
        setLoungesFetchResponse({
          status: response.success ? "success" : "error",
          message: response.user_message,
        });
      })();
    } else {
      (async () => {
        const initialFilter = await setInitialFilter(lounges);
        if (servicesFetchResponse.status !== "success")
          loadServiceReport(initialFilter);
      })();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loadServiceReport };
};

export default useLoadServiceReport;
