import React, { useEffect } from "react";
import { getSaleBoxes } from "@Adapters/saleboxes/saleboxreport/getSaleBoxes.adapter";
import { STATUS_OK } from "@Models/httpsStatus";
import { BASE_ROUTE } from "@Models/routes";
import { useParams, useNavigate } from "react-router-dom";
import { ISaleBox } from "@Models/interfaces/saleboxes";
import { useSaleBoxesStore } from "@Stores/saleBoxesStore";

const useValidateSalebox = (
  setFetchResponses: React.Dispatch<
    React.SetStateAction<{
      saleBoxes: {
        loading: boolean;
        error: boolean;
      };
    }>
  >
) => {
  const { saleBoxId } = useParams<{ saleBoxId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    getSaleBoxes().then((res) => {
      if (res.status === STATUS_OK) {
        const saleBoxFound: ISaleBox = res.data?.find(
          (saleBox: ISaleBox) => saleBox.id === Number(saleBoxId)
        );
        if (saleBoxFound?.isOpen && !saleBoxFound?.isDowngraded) {
          useSaleBoxesStore.getState().updateSaleBoxOnList(saleBoxFound);
          setFetchResponses((prev) => ({
            ...prev,
            saleBoxes: {
              loading: false,
              error: false,
            },
          }));
        } else {
          localStorage.removeItem("lastSaleboxVisited");
          // navigate(BASE_ROUTE);
          setFetchResponses((prev) => ({
            ...prev,
            saleBoxes: {
              loading: false,
              error: false,
            },
          }));
        }
      } else {
        localStorage.removeItem("lastSaleboxVisited");
        // navigate(BASE_ROUTE);
        setFetchResponses((prev) => ({
          ...prev,
          saleBoxes: {
            loading: false,
            error: false,
          },
        }));
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, saleBoxId]);
};

export default useValidateSalebox;
