import React, { useState, ReactNode } from "react";
import styles from "./CustomTable.module.css";

export interface Column<T> {
  header: string;
  accessor: keyof T | ((item: T) => ReactNode);
}

interface CustomTableProps<T> {
  data: T[];
  columns: Column<T>[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
  emptyMessage?: string;
}

function CustomTable<T extends { id: string | number }>({
  data,
  columns,
  currentPage,
  setCurrentPage,
  totalPages,
  emptyMessage = "No se han encontrado elementos con estos parámetros de búsqueda.",
}: CustomTableProps<T>) {
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderCell = (item: T, column: Column<T>): ReactNode => {
    if (typeof column.accessor === "function") {
      return column.accessor(item);
    }
    return item[column.accessor] as ReactNode;
  };

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead className={styles.table_header}>
          <tr>
            <th></th>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td colSpan={columns.length + 1} style={{ whiteSpace: "nowrap" }}>
                {emptyMessage}
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr
                key={item.id}
                className={index % 2 === 0 ? styles.evenRow : styles.oddRow}
              >
                <td>{(currentPage - 1) * data.length + index + 1}</td>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{renderCell(item, column)}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>

      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.pageButton}
          >
            &lt;
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`${styles.pageButton} ${
                currentPage === page ? styles.activePage : ""
              }`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={styles.pageButton}
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
}

export default CustomTable;
