import React, { useState } from "react";
import style from "./SaleboxReportFilter.module.css";
// @ts-ignore
import { ButtonWithoutIcon, DateInput, SelectInput } from "@viuti/recursos";
import { getSaleBoxReport } from "@Adapters/saleboxes/saleboxreport/getSaleBoxReport.adapter";
import { useSaleBoxReportStore } from "@Stores/saleBoxReportStore";
import { useSaleBoxesStore } from "@Stores/saleBoxesStore";

interface ISaleboxReportFilterProps {
  isDisabled?: boolean;
  setSePresionoBoton?: (value: boolean) => void;
}

const SaleboxReportFilter = ({
  isDisabled = false,
  setSePresionoBoton = () => {},
}: ISaleboxReportFilterProps) => {
  const values = useSaleBoxReportStore((state) => ({
    salebox: state.salebox,
    fromDate: state.fromDate,
    toDate: state.toDate,
  }));
  const saleBoxes = useSaleBoxesStore((state) => state.saleBoxes);
  const [isSubmitPending, setIsSubmitPending] = useState(false);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const name = e.target?.name || "salebox";
    let value = e.target?.value || e;

    if (name === "salebox" && typeof value === "object") {
      value = value.id;
    }

    useSaleBoxReportStore.getState().updateReportFilters({
      target: {
        name,
        value,
      },
    });
  };

  const onSubmit = async () => {
    setIsSubmitPending(true);
    const selectedBox =
      saleBoxes.find((box) => box.id === Number(values.salebox)) || "";
    await getSaleBoxReport({ ...values, salebox: selectedBox });
    setIsSubmitPending(false);
    setSePresionoBoton(true);
  };

  return (
    <div className={style.filter__container}>
      <span className={style.filter__salebox}>
        <SelectInput
          label="Caja"
          value={
            saleBoxes.find((box) => box.id === Number(values.salebox)) || ""
          }
          items={saleBoxes}
          handleChange={handleValueChange}
          name="salebox"
        />
      </span>
      <span className={style.filter__dates}>
        <span className={style.filter__dates__input}>
          <DateInput
            label="Desde"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: values.fromDate,
              onChange: handleValueChange,
              name: "fromDate",
            }}
          />
        </span>
        <span className={style.filter__dates__input}>
          <DateInput
            label="Hasta"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: values.toDate,
              onChange: handleValueChange,
              name: "toDate",
            }}
          />
        </span>
        {!isDisabled && (
          <ButtonWithoutIcon
            isPrimary={false}
            textBttn={"Cargar"}
            handleClick={onSubmit}
            disabled={
              values.salebox === "" ||
              values.fromDate === "" ||
              values.toDate === "" ||
              isSubmitPending
            }
            isLoading={isSubmitPending}
          />
        )}
      </span>
    </div>
  );
};

export default SaleboxReportFilter;
