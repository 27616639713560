import React from "react";
import style from "./PaymentMethodsList.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { CardIcon, CashIcon, CommandIcon, WalletIcon } from "@Models/icons";

import { formatToSoles } from "@Utilities/FormatsHandler";
import EmptyMessage from "@Components/ui/molecules/EmptyMessage/EmptyMessage";
import { useSaleBoxReportStore } from "@Stores/saleBoxReportStore";
import {
  CREDIT_CARD_PAYMENT_METHODS,
  DEBIT_CARD_PAYMENT_METHODS,
  DIGITAL_WALLET_PAYMENT_METHODS,
  EFFECTIVE_PAYMENT_METHODS,
  TRANSFER_PAYMENT_METHODS,
} from "@Models/consts/paymentMethods";
import { capitalizeFirstLetter } from "@Utilities/CapitalizeWords";

const PaymentMethodsList = () => {
  const paymentMethods =
    useSaleBoxReportStore((state) => state.report.payments) || [];

  const ItemIcon = (category: string) => {
    switch (category) {
      case EFFECTIVE_PAYMENT_METHODS:
        return CashIcon;
      case CREDIT_CARD_PAYMENT_METHODS:
        return CardIcon;
      case DEBIT_CARD_PAYMENT_METHODS:
        return CardIcon;
      case TRANSFER_PAYMENT_METHODS:
        return CashIcon;
      case DIGITAL_WALLET_PAYMENT_METHODS:
        return WalletIcon;
      default:
        break;
    }
  };

  return (
    <article className={style.container}>
      <h4 className={style.label}>Medios de pago (en montos)</h4>
      <div className={style.content}>
        {paymentMethods.length === 0 && (
          <EmptyMessage
            icon={{ name: CommandIcon, size: 40 }}
            text="No hay pagos registrados."
            subtext="Realiza un pago para visualizarlo aquí."
          />
        )}
        {paymentMethods.map((method) => {
          const uniqueKey = `${method.subCategory}-${method.amount}`;
          return (
            <div key={uniqueKey} className={style.method}>
              <span className={style.method__info}>
                <Icon
                  path={ItemIcon(method.category)}
                  color={"#937cf4"}
                  size={32}
                />
                <p className={style.method__name}>
                  {capitalizeFirstLetter(method.subCategory)}{" "}
                  {method.category !== "efectivo" &&
                    `(${method.category.toLowerCase()})`}
                </p>
              </span>
              <p className={style.method__amount}>
                {formatToSoles(method.amount)}
              </p>
            </div>
          );
        })}
      </div>
    </article>
  );
};

export default PaymentMethodsList;
