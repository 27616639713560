import { exportWarehouseReport_service } from "@Services/warehouses";
import { downloadCSV } from "@Utilities/DownloadFile";

export const exportWarehouseReport = async (
  shareType: string,
  loungeId: string
) => {
  let response: IResponse;

  switch (shareType) {
    case "csv":
      response = await exportWarehouseReport_service(loungeId);

      if (!response.success) {
        return response;
      }

      let file = {
        file: `data:application/pdf;base64,${response.data}`,
        file_name: "Reporte de movimientos de almacenes",
      };

      downloadCSV(file);
      return response;
    default:
      break;
  }

  return response;
};
