import { useEffect, useState, useCallback } from "react";
import {
  IWarehousesReportStore,
  useWarehousesReportStore,
} from "@Stores/useWarehousesReportStore";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";
import {
  getWarehousesReport_service,
  getWarehouses_service,
} from "@Services/warehouses";
import { formatToSoles } from "@Utilities/FormatsHandler";

const useLoadWarehouseReport = () => {
  const {
    filter,
    setFilter,
    setWarehouses,
    setWarehousesFetchResponse,
    warehousesList,
    setWarehousesList,
  } = useWarehousesReportStore() as IWarehousesReportStore;
  const { setToaster } = useToasterStore() as IToasterStore;
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const loadWarehousesList = useCallback(async () => {
    const response = await getWarehouses_service();
    if (!response.success) {
      setToaster(response.user_message, response.status, true);
    }

    const warehouses = response.data.map((warehouse) => ({
      value: warehouse.warehouseId,
      name: warehouse.warehouseName,
      sale: warehouse.sale,
    }));
    setWarehousesList(warehouses);
    if (warehouses.length > 0) {
      const firstWarehouse = warehouses[0];
      const newFilter = {
        ...filter,
        warehouseId: firstWarehouse.value,
        warehouseName: firstWarehouse.name,
        sales: firstWarehouse.sale,
      };
      setFilter(newFilter);
    }

    return {
      ...response,
      data: warehouses,
    };
  }, [setWarehousesList, filter, setFilter, setToaster]);

  const loadWarehouseReport = useCallback(async () => {
    if (!filter.warehouseId) {
      return;
    }

    setWarehousesFetchResponse({
      status: "loading",
      message: null,
    });

    const payload = { ...filter };
    const setters = {
      setToaster,
      setWarehouses,
      setWarehousesFetchResponse,
    };
    await getWarehousesReport(setters, payload);
  }, [filter, setToaster, setWarehouses, setWarehousesFetchResponse]);

  const setInitialFilter = useCallback(
    (tempWarehousesList) => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      const formattedLastDate = new Date(
        currentDate.setFullYear(currentDate.getFullYear() - 1)
      )
        .toISOString()
        .split("T")[0];

      const firstWarehouse = tempWarehousesList[0];
      const newFilter = {
        ...filter,
        warehouseId: firstWarehouse.value.toString(),
        warehouseName: firstWarehouse.name,
        sales: firstWarehouse.sale,
        fromDate: formattedLastDate,
        toDate: formattedDate,
      };
      setFilter(newFilter);
    },
    [setFilter, filter]
  );

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      loadWarehousesList().then((res) => {
        setInitialFilter(res.data);
      });
    }
  }, [isInitialLoad, loadWarehousesList, setInitialFilter]);

  useEffect(() => {
    if (filter.warehouseId && filter.fromDate && filter.toDate) {
      loadWarehouseReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.warehouseId]);

  return { loadWarehouseReport, loadWarehousesList };
};

export default useLoadWarehouseReport;

const getWarehousesReport = async (setters, payload) => {
  const { setToaster, setWarehouses, setWarehousesFetchResponse } = setters;
  const response = await getWarehousesReport_service(payload);

  if (response.success) {
    const warehouses = response.data.items.map((item) => ({
      ...item,
      date: item.date.split("T")[0],
      id: item.productCode,
      name: item.productName,
      unitCost: formatToSoles(item.unitCost),
      totalCost: formatToSoles(item.totalCost),
    }));
    setWarehouses(warehouses);
    setWarehousesFetchResponse({
      status: "success",
      message: response.user_message,
    });
  } else {
    setToaster(response.user_message, response.status, true);
    setWarehousesFetchResponse({
      status: "error",
      message: response.user_message,
    });
  }
};
