import React, { useContext, useEffect, useState } from "react";
import style from "./ClientsReport.module.css";
import {
  HeaderNavigation,
  LoadingScreen,
  SearchWithColoredBorder,
  // @ts-ignore
} from "@viuti/recursos";
import { navigateTo } from "@Utilities/navigate";
import { useNavigate } from "react-router-dom";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import { CLIENT_REPORT_NAME } from "@Models/consts";
import { changeModalExport } from "@Hooks/changeModalExport";
import ClientReportFilter from "@Components/ui/molecules/ClientReportFilter/ClientReportFilter";
import useLoadClientReport from "@Hooks/useLoadClientReport";
import {
  IClientsReportStore,
  useClientsReportStore,
} from "@Stores/useClientsReportStore";
import ClientsTable from "@Components/ui/organisms/ClientsTable/ClientsTable";
import { filteredClients } from "./functions/filterClientsBySearch";
import useExportClientsReport from "@Components/ui/organisms/ExportClientsReportModal/hooks/useExportClientsReport";
import { FILE_EXTENSION } from "@Models/interfaces/reports";

const ClientsReport = () => {
  const { loadClientReport } = useLoadClientReport();
  const navigate = useNavigate();
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  const { loungesFetchResponse } = useLoungesStore() as ILoungesStore;
  const { clientsCategoriesFetchResponse, clientsFetchResponse, clients } =
    useClientsReportStore() as IClientsReportStore;

  const [searchInput, setSearchInput] = useState("");
  const clientsList = filteredClients(clients, searchInput);

  const { onSubmit, isCsvPending } = useExportClientsReport({ modalContext });

  return (
    <div id="viuti-front-mainContent">
      <div className={style.view__container}>
        <HeaderNavigation
          title={"Reporte de clientes"}
          previousAction={() => navigateTo(navigate, "/")}
          previousActionMovement={"back"}
          buttonProps={{
            textBttn: "Exportar CSV",
            handleClick: () => onSubmit({ shareType: FILE_EXTENSION.CSV }),
            // changeModalExport(CLIENT_REPORT_NAME, modalContext),
            isDisabled: isCsvPending,
            isLoading: isCsvPending,
            isPrimary: true,
            isHidden: false,
          }}
        />
        {loungesFetchResponse.status === "loading" ||
        clientsCategoriesFetchResponse.status === "loading" ||
        clientsFetchResponse.status === "loading" ? (
          <LoadingScreen visible={true} />
        ) : (
          <>
            <h3 className={style.view__instructions}>
              Visualiza y analiza los datos de tus clientes para mejorar la toma
              de decisiones en tu negocio.
            </h3>
            <div className={style.view__content}>
              <ClientReportFilter loadReportAction={loadClientReport} />
              <div className={style.search__container}>
                <SearchWithColoredBorder
                  value={searchInput}
                  placeholder={"Buscar cliente"}
                  setValue={setSearchInput}
                  allData={clientsList}
                  showResults={false}
                  clearInput={false}
                  disabled={false}
                  searchBy={["name"]}
                />
              </div>
              <div className={style.report_container}>
                <div
                  className={`${style.card__container} ${style.pie_graph__container}`}
                >
                  <h4 className={style.title}>Mis clientes</h4>
                  <ClientsTable clients={clientsList} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClientsReport;
