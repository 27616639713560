import { IProduct } from "@Models/interfaces/products";
import { PieValueType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import { getProducts_service } from "@Services/products";

export const getProductsReport = async (
  setters: {
    setToaster: (message: string, status: number, loading: boolean) => void;
    setPages: (pages: number) => void;
    setProducts: (products: IProduct[]) => void;
    setTopProducts: (products: IProduct[]) => void;
    setPiegraphProductsData: (
      piegraphProductsData: MakeOptional<PieValueType, "id">[]
    ) => void;
    setProductsFetchResponse: (response: IFetchReportResponse) => void;
  },
  payload: {
    loungeId: string;
    fromDate: string;
    toDate: string;
    page: number;
  }
) => {
  const {
    setToaster,
    setPages,
    setProducts,
    setTopProducts,
    setPiegraphProductsData,
    setProductsFetchResponse,
  } = setters;

  const response: IResponse = await getProducts_service(payload);

  setProductsFetchResponse({
    status: response.success ? "success" : "error",
    message: response.user_message,
  });

  if (!response.success) {
    setToaster(response.user_message, response.status, false);
    return response;
  }

  const products: IProduct[] = response.data.products.map((service) => {
    return {
      id: service.id,
      name: service.name,
      quantity: service.quantity,
      totalRevenue: service.totalRevenue,
    };
  });

  const topProducts: IProduct[] = products
    .sort((a, b) => b.totalRevenue - a.totalRevenue)
    .slice(0, 10);

  const piegraphProductsData: MakeOptional<PieValueType, "id">[] =
    topProducts.map((service) => {
      return {
        id: service.id,
        value: service.totalRevenue,
        label: service.name,
      };
    });

  setPages(response.data.totalPages);
  setProducts(products);
  setTopProducts(topProducts);
  setPiegraphProductsData(piegraphProductsData);
};
