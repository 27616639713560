import React, { useState } from "react";
//@ts-ignore
import { SaleDocumentReportView } from "@viuti/recursos";
import useBoxes from "@Pages/SaleMovements/hooks/useBoxes/useBoxes";
import { getAccountInfo } from "@Adapters/saleboxes/documents/getAccountInfo.adapter";
import { getSalesDocuments } from "@Adapters/saleboxes/documents/getSalesDocuments.adapter";
import { EXPORT_SALE_DOCUMENTS_UUID } from "@Models/consts/securityAccess";
import { state, stateFE, typeDocuments } from "./consts/constSalesDocuments";
import { useToasterStore, IToasterStore } from "@Stores/useToasterStore";
import { useSaleDocumentsStore } from "@Stores/useSaleDocumentsStore";
import { useNavigate } from "react-router-dom";

const SalesDocuments = () => {
  const navigate = useNavigate();
  const { setToaster } = useToasterStore() as IToasterStore;
  const { boxes, currentBoxId } = useBoxes();

  const [isSaleDetailsModalOpen, setIsSaleDetailsModalOpen] = useState(false);
  const [isCancelTicketModalOpen, setIsCancelTicketModalOpen] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isCreditNoteModalVisible, setIsCreditNoteModalVisible] =
    useState(false);

  const {
    saleDocuments,
    totalPages: pages,
    currentPagination,
    shouldReloadTable,
    isLoadingDocuments,
    setIsLoadingDocuments,
    setShouldReloadTable,
    setCurrentPagination,
  } = useSaleDocumentsStore();

  const triggerToast = ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number;
    toastTimeDuration: number | string;
  }) => {
    setToaster(message, status, false, toastTimeDuration);
  };

  return (
    <SaleDocumentReportView
      boxes={boxes}
      saleBoxId={currentBoxId}
      saleDocuments={saleDocuments}
      pages={pages}
      currentPagination={currentPagination}
      shouldReloadTable={shouldReloadTable}
      getAccountInfo={getAccountInfo}
      getSalesDocuments={async (form, page) => {
        setIsLoadingDocuments(true);
        await getSalesDocuments(form, page);
        setIsLoadingDocuments(false);
      }}
      updateShouldReloadTable={setShouldReloadTable}
      updateCurrentPagination={setCurrentPagination}
      onNotification={triggerToast}
      saleDetailsModalVisibility={{
        isVisible: isSaleDetailsModalOpen,
        setIsVisible: setIsSaleDetailsModalOpen,
      }}
      cancelTicketModalVisibility={{
        isVisible: isCancelTicketModalOpen,
        setIsVisible: setIsCancelTicketModalOpen,
      }}
      exportModalVisibility={{
        isVisible: isExportModalVisible,
        setIsVisible: setIsExportModalVisible,
      }}
      creditNoteModalVisibility={{
        isVisible: isCreditNoteModalVisible,
        setIsVisible: setIsCreditNoteModalVisible,
      }}
      EXPORT_SALE_DOCUMENTS_UUID={EXPORT_SALE_DOCUMENTS_UUID}
      onHeaderBackClick={() => navigate("/")}
      typeDocuments={typeDocuments}
      state={state}
      stateFE={stateFE}
      setCurrentPagination={(page) => {
        setIsLoadingDocuments(true);
        setCurrentPagination(page);
      }}
    />
  );
};

export default SalesDocuments;
