import React from "react";
import {
  IWarehousesReportStore,
  useWarehousesReportStore,
} from "@Stores/useWarehousesReportStore";
import CustomTable from "@Components/ui/molecules/CustomTable/CustomTable";
import { IWarehouse } from "@Models/interfaces/warehouses";
import { useNavigate } from "react-router-dom";
import styles from "./WarehousesTable.module.css";
const WarehousesTable = () => {
  const navigate = useNavigate();
  const { warehouses } = useWarehousesReportStore() as IWarehousesReportStore;

  const columns = [
    {
      header: "Fecha",
      accessor: (warehouse: IWarehouse) => warehouse.date,
    },
    { header: "Hora", accessor: (warehouse: IWarehouse) => warehouse.time },
    {
      header: "Nombre",
      accessor: (warehouse: IWarehouse) => (
        <a
          href={`/almacen/detalles/${warehouse.id}`}
          target="_blank"
          className={styles.link}
        >
          {warehouse.name}
        </a>
      ),
    },
    {
      header: "Descripción",
      accessor: (warehouse: IWarehouse) => warehouse.description,
    },
    {
      header: "Documento",
      accessor: (warehouse: IWarehouse) => warehouse.document,
    },
    {
      header: "Entradas",
      accessor: (warehouse: IWarehouse) => warehouse.entries,
    },
    {
      header: "Salidas",
      accessor: (warehouse: IWarehouse) => warehouse.exits,
    },
    {
      header: "Balance",
      accessor: (warehouse: IWarehouse) => warehouse.balance,
    },
    {
      header: "Costo Unitario",
      accessor: (warehouse: IWarehouse) => warehouse.unitCost,
    },
    {
      header: "Costo Total",
      accessor: (warehouse: IWarehouse) => warehouse.totalCost,
    },
  ];

  return (
    <CustomTable
      data={warehouses}
      columns={columns}
      currentPage={1}
      setCurrentPage={() => {}}
      totalPages={1}
    />
  );
};

export default WarehousesTable;
