import {
  IProduct,
  IPieGraphData,
  IProductsFilter,
} from "@Models/interfaces/products";
import { PieValueType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import { create } from "zustand";

export interface IProductsReportStore {
  filter: IProductsFilter;
  setFilter: (filter: IProductsFilter) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  pages: number;
  setPages: (pages: number) => void;
  products: IProduct[];
  setProducts: (products: IProduct[]) => void;
  topProducts: IProduct[];
  setTopProducts: (products: IProduct[]) => void;
  piegraphProductsData: MakeOptional<PieValueType, "id">[];
  setPiegraphProductsData: (
    piegraphProductsData: MakeOptional<PieValueType, "id">[]
  ) => void;
  productsFetchResponse: IFetchReportResponse;
  setProductsFetchResponse: (response: IFetchReportResponse) => void;
}

export const useProductsReportStore = create((set) => ({
  filter: { loungeId: "", fromDate: "", toDate: "" },
  setFilter: (filter: IProductsFilter) => set({ filter }),
  currentPage: 1,
  setCurrentPage: (page: number) => set({ currentPage: page }),
  pages: 1,
  setPages: (pages: number) => set({ pages }),
  products: [],
  setProducts: (products: IProduct[]) => set({ products }),
  topProducts: [],
  setTopProducts: (topProducts: IProduct[]) => set({ topProducts }),
  piegraphProductsData: [],
  setPiegraphProductsData: (piegraphProductsData: IPieGraphData) => {
    set({ piegraphProductsData });
  },
  productsFetchResponse: {
    status: "loading",
    message: "",
  },
  setProductsFetchResponse: (response: IFetchReportResponse) =>
    set({ productsFetchResponse: response }),
}));
