import axios from "axios";
import { config, reportsBaseUrl } from "./constGlobals";

export const getProducts_service = async ({
  loungeId,
  fromDate,
  toDate,
  page,
}) => {
  // const response = {
  //   success: true,
  //   status: 200,
  //   user_message: "Productos obtenidos correctamente.",
  //   developer_message: "Productos obtenidos correctamente.",
  //   data: PRODUCTS_MOCKS,
  // };

  const url = `${reportsBaseUrl}/products/report`;
  try {
    const response: IResponse = await axios
      .get(url, {
        ...config,
        params: { loungeId, fromDate, toDate, page },
      })
      .then((res) => res.data);
    return response;
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se ha podido conseguir los reportes de productos.",
      developer_message: null,
      success: false,
      data: null,
    };
  }
};

export const exportProductReport_service = async (loungeId: string) => {
  // const response = {
  // 	status: 200,
  // 	user_message: 'Exportación de productos a CSV realizada correctamente',
  // 	developer_message: null,
  // 	success: true,
  // 	data: 'IklEIiwiTm9tYnJlIiwiQ2FudGlkYWQiLCJJbmdyZXNvcyBUb3RhbGVzIgoyNTU5MTQsIkxhY2EgRmlqYWRvcmEiLDEyLDMwMAoyNTU5ODMsIlRpbnRlIHBhcmEgQ2FiZWxsbyIsMiwxMjAKMjU1OTAyLCJFc3BvbmphcyIsMywzMAoyNTU4OTcsIkphYm9uZXMiLDEsMzAKMjU1OTgwLCJDcmVtYSBwYXJhIFBlaW5hciIsMSwxNi45NA==',
  // };

  const url = `${reportsBaseUrl}/products/export`;
  try {
    const response: IResponse = await axios
      .get(url, {
        ...config,
        params: { loungeId },
      })
      .then((res) => res.data);
    return response;
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      success: false,
      user_message:
        error?.response?.data?.message ||
        "No se ha podido exportar el reporte de productos.",
      developer_message: null,
      data: null,
    };
  }
};
