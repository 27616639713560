import axios from "axios";
import { config, loungesBaseUrl, saleboxesBaseUrl } from "./constGlobals";

export const getConceptMovements_service = async () => {
  const url = `${loungesBaseUrl}/Concept/Concepts`;

  try {
    const response = await axios.get(url, config).then((res) => res.data);
    return {
      success: true,
      status: response.status,
      user_message: response.message,
      developer_message: response.developer_message,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los conceptos de movimiento.",
      developer_message: null,
      data: [],
    };
  }
};

export const postConceptMovements_service = async (data) => {
  const url = `${loungesBaseUrl}/Concept/Concept`;
  // const crea_concepto_movimiento = 63;
  // trackUserAction(crea_concepto_movimiento);

  try {
    const response = await axios
      .post(url, data, config)
      .then((res) => res.data);
    return {
      success: true,
      status: response.status,
      user_message: response.message,
      developer_message: response.developer_message,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "Hubo un error al crear el concepto de movimiento. Intentalo de nuevo.",
      developer_message: null,
      data: [],
    };
  }
};

export const downloadCashMovements_service = async (payload): Promise<any> => {
  const url = `${saleboxesBaseUrl}/CashMovements/DownloadCashMovement?saleBoxId=${payload.idBox}&typeMovement=${payload.type}&typeMovementConcept=${payload.concept}&startDate=${payload.startDate}&endDate=${payload.endDate}&searchParameter=${payload.searchParameter}`;
  // const descarga_movimientos = 62;
  // trackUserAction(descarga_movimientos);
  try {
    const response = await axios.get(url, config);
    return {
      isSuccessful: true,
      status: response.status,
      message: response.data.message || "Movimientos descargados correctamente",
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message: error?.response?.data.message || "Error al descargar el reporte",
      data: [],
    };
  }
};
