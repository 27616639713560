// @ts-ignore
import React, { useEffect, useState } from "react";
import style from "./SaleboxReport.module.css";
// @ts-ignore
import { HeaderNavigation, validateModuleAccess } from "@viuti/recursos";
import { redirectToMainPage } from "@Utilities/Navigation";
import useValidateSalebox from "@Hooks/useValidateSalebox";
import SaleboxReportFilter from "@Components/ui/molecules/SaleboxReportFilter/SaleboxReportFilter";
import { getSaleBoxReport } from "@Adapters/saleboxes/saleboxreport/getSaleBoxReport.adapter";
import { LoadingPulseIcon } from "@Models/icons";
import { useParams } from "react-router-dom";
import { STATUS_OK } from "@Models/httpsStatus";
import { postShareReport } from "@Adapters/saleboxes/saleboxreport/postShareReport.adapter";
import { ISaleBox } from "@Models/interfaces/saleboxes";
import ReportContent from "@Components/ui/organisms/ReportContent/ReportContent";
import { EXPORT_SALEBOX_REPORT_UUID } from "@Models/consts/securityAccess";
import { useSaleBoxesStore } from "@Stores/saleBoxesStore";
import { useSaleBoxReportStore } from "@Stores/saleBoxReportStore";

interface ReportValues {
  salebox: ISaleBox | "";
  fromDate: string;
  toDate: string;
}

const SaleboxReport = () => {
  const saleBoxes = useSaleBoxesStore((state) => state.saleBoxes);
  const reportResponse = useSaleBoxReportStore((state) => state.fetchResponse);
  const currentDate = new Date();
  const [isDownloading, setIsDownloading] = useState(false);
  const values = useSaleBoxReportStore((state) => ({
    salebox: state.salebox,
    fromDate: state.fromDate,
    toDate: state.toDate,
  }));
  const [sePresionoBoton, setSePresionoBoton] = useState(false);

  const sitesHeaderProps = {
    textBttn: isDownloading ? "Descargando..." : "Exportar reporte",
    handleClick: async () => {
      const uuidToValidate = EXPORT_SALEBOX_REPORT_UUID;
      const isAccessOk = validateModuleAccess(uuidToValidate);
      if (isAccessOk && !isDownloading) {
        setIsDownloading(true);
        try {
          const selectedBox = saleBoxes.find(
            (box) => box.id === Number(values.salebox)
          ).id;
          await postShareReport("download", {
            idSalebox: selectedBox,
            startDate: values.fromDate,
            endDate: values.toDate,
          });
        } finally {
          setIsDownloading(false);
        }
      }
    },
    isDisabled: isDownloading || !sePresionoBoton,
    isHidden: false,
    isPrimary: true,
    isLoading: isDownloading,
  };

  const headerProps = {
    title: "Reporte de caja",
    previousAction: () => redirectToMainPage("mi-caja"),
    previousActionMovement: "back",
    buttonProps: sitesHeaderProps,
  };

  const [fetchResponses, setFetchResponses] = useState<{
    saleBoxes: {
      loading: boolean;
      error: boolean;
    };
  }>({
    saleBoxes: {
      loading: true,
      error: false,
    },
  });

  useValidateSalebox(setFetchResponses);

  useEffect(() => {
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    if (
      fetchResponses.saleBoxes.loading === false &&
      fetchResponses.saleBoxes.error === false
    ) {
      const saleBox = saleBoxes.find(
        (saleBox) => saleBox.id === Number(values.salebox)
      );
      const newValues: ReportValues = {
        salebox: saleBox || "",
        fromDate: formattedDate,
        toDate: formattedDate,
      };

      useSaleBoxReportStore.getState().updateReportFilters({
        target: { name: "salebox", value: newValues.salebox },
      } as React.ChangeEvent<HTMLInputElement>);
      useSaleBoxReportStore.getState().updateReportFilters({
        target: { name: "fromDate", value: newValues.fromDate },
      } as React.ChangeEvent<HTMLInputElement>);
      useSaleBoxReportStore.getState().updateReportFilters({
        target: { name: "toDate", value: newValues.toDate },
      } as React.ChangeEvent<HTMLInputElement>);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResponses.saleBoxes.loading]);

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation {...headerProps} />
      <main className={style.body}>
        {fetchResponses.saleBoxes.loading ? (
          <div className={style.loading}>
            <img src={LoadingPulseIcon} alt="Cargando..." />
          </div>
        ) : (
          <>
            <SaleboxReportFilter setSePresionoBoton={setSePresionoBoton} />
            {!sePresionoBoton ? (
              <div className={style.message}>
                <p>
                  Por favor selecciona una caja y haga click en el botón
                  "Cargar" para mostrar el reporte que desea ver.
                </p>
              </div>
            ) : reportResponse.status !== STATUS_OK ? (
              <div className={style.error}>
                <p className={style.error_message}>
                  No hemos podido obtener el reporte de la caja.
                </p>
                <p className={style.error_submessage}>
                  {reportResponse.message}
                </p>
              </div>
            ) : (
              <ReportContent />
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default SaleboxReport;
