// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ypY0SXE0N9lCBIGYuhbn {
  width: 460px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
}

@media screen and (width < 668px) {
  .ypY0SXE0N9lCBIGYuhbn {
    width: 300px;
  }
}

@media screen and (width < 490px) {
  .ypY0SXE0N9lCBIGYuhbn {
    width: 250px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/hooks/changeModalType/changeModalType.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".header_title_container {\n  width: 460px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 0.8rem;\n}\n\n@media screen and (width < 668px) {\n  .header_title_container {\n    width: 300px;\n  }\n}\n\n@media screen and (width < 490px) {\n  .header_title_container {\n    width: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_title_container": `ypY0SXE0N9lCBIGYuhbn`
};
export default ___CSS_LOADER_EXPORT___;
