import React, { useEffect, useRef } from "react";
import style from "./TopProductsList.module.css";
import { IProduct } from "@Models/interfaces/products";
import {
  IProductsReportStore,
  useProductsReportStore,
} from "@Stores/useProductsReportStore";
import { formatToSoles } from "@Utilities/FormatsHandler";

interface ITopProductsList {
  highlightedProduct: IProduct;
}

const TopProductsList: React.FC<ITopProductsList> = ({
  highlightedProduct,
}) => {
  const { topProducts } = useProductsReportStore() as IProductsReportStore;
  const listRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    if (highlightedProduct && listRef.current) {
      const highlightedIndex = topProducts.findIndex(
        (product) => product.id === highlightedProduct.id
      );
      const highlightedItem = itemRefs.current[highlightedIndex];

      if (highlightedItem) {
        const listRect = listRef.current.getBoundingClientRect();
        const itemRect = highlightedItem.getBoundingClientRect();
        const offset = itemRect.top - listRect.top;

        listRef.current.scrollBy({
          top: offset - listRect.height / 2 + itemRect.height / 2,
          behavior: "smooth",
        });
      }
    }
  }, [highlightedProduct, topProducts]);

  return (
    <div className={style.products_list__container} ref={listRef}>
      {topProducts.map((product, index) => {
        const isHighlighted = highlightedProduct?.id === product.id;

        return (
          <button
            key={product.id}
            className={`${style.product__button} ${
              isHighlighted ? style.product__highlighted : ""
            }`}
            ref={(el) => (itemRefs.current[index] = el)}
          >
            <div className={style.product__identifier}>
              <span className={style.product_position}>#{index + 1}</span>
              <p className={style.product_name}>{product.name}</p>
            </div>
            <div className={style.product__revenue}>
              {formatToSoles(product.totalRevenue)}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default TopProductsList;
