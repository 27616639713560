import axios from "axios";
import { config, reportsBaseUrl } from "./constGlobals";

export const getServices_service = async ({
  loungeId,
  fromDate,
  toDate,
  page,
}) => {
  const url = `${reportsBaseUrl}/services/report`;

  try {
    const response: IResponse = await axios
      .get(url, {
        ...config,
        params: { loungeId, fromDate, toDate, page },
      })
      .then((res) => res.data);
    return response;
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se han podido conseguir los servicios del local.",
      developer_message: error?.response?.data?.developer_message,
      data: null,
    };
  }
};

export const exportServiceReport_service = async (loungeId: string) => {
  const url = `${reportsBaseUrl}/services/export`;

  try {
    const response: IResponse = await axios.get(url, {
      ...config,
      params: { loungeId },
    });
    return response.data;
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se ha podido exportar el reporte de servicios.",
      developer_message: error?.response?.data?.developer_message,
      data: null,
    };
  }
};
