import axios from "axios";
import { config, reportsBaseUrl, warehousesBaseUrl } from "./constGlobals";

export const getWarehouses_service = async () => {
  const url = `${warehousesBaseUrl}/Warehouses`;
  try {
    const response: IResponse = await axios.get(url, config);
    return {
      status: response.status,
      user_message: "Almacenes obtenidos correctamente.",
      developer_message: null,
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se ha podido obtener la lista de almacenes.",
      developer_message: null,
      success: false,
      data: null,
    };
  }
};

export const getWarehousesReport_service = async ({
  warehouseId,
  fromDate,
  toDate,
  page,
}) => {
  const url = `${reportsBaseUrl}/warehouses/report`;
  try {
    const response: IResponse = await axios
      .get(url, {
        ...config,
        params: { warehouseId, fromDate, toDate, page },
      })
      .then((res) => res.data);
    return response;
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se ha podido conseguir los reportes de almacenes.",
      developer_message: null,
      success: false,
      data: null,
    };
  }
};

export const exportWarehouseReport_service = async (warehouseId: string) => {
  const url = `${reportsBaseUrl}/warehouses/export`;
  try {
    const response: IResponse = await axios
      .get(url, {
        ...config,
        params: { warehouseId },
      })
      .then((res) => res.data);
    return {
      status: response.status,
      user_message:
        "Reporte de movimientos de almacenes exportado correctamente.",
      developer_message: null,
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "No se ha podido exportar el reporte de movimientos de almacenes.",
      developer_message: null,
      success: false,
      data: null,
    };
  }
};
