export type T_CLIENT_REPORT_NAME = "Clientes";
export type T_PRODUCT_REPORT_NAME = "Productos";
export type T_SERVICE_REPORT_NAME = "Servicios";
export type T_WAREHOUSE_REPORT_NAME = "Movimientos de almacenes";
export type T_SALE_MOVEMENTS_REPORT_NAME = "Movimientos de venta";
export type T_SALE_DOCUMENTS_REPORT_NAME = "Documentos de venta";
export const CLIENT_REPORT_NAME = "Clientes";
export const PRODUCT_REPORT_NAME = "Productos";
export const SERVICE_REPORT_NAME = "Servicios";
export const WAREHOUSE_REPORT_NAME = "Movimientos de almacenes";
export const SALE_MOVEMENTS_REPORT_NAME = "Movimientos de venta";
export const SALE_DOCUMENTS_REPORT_NAME = "Documentos de venta";
