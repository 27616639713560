import { STATUS_OK } from "@Models/httpsStatus";
import { getAccountInfo_service } from "@Services/accountInfo";
import { useSaleDocumentsStore } from "@Stores/useSaleDocumentsStore";

export const getAccountInfo = async () => {
  const {
    setAccountInfo,
    setIsLoadingAccount,
    setAccountError,
    updateAccountFromLocalStorage,
  } = useSaleDocumentsStore.getState();

  try {
    setIsLoadingAccount(true);

    // Intentar obtener del localStorage primero
    updateAccountFromLocalStorage();
    const storedInfo = useSaleDocumentsStore.getState().accountInfo;

    if (storedInfo) {
      return {
        isSuccessful: true,
        status: STATUS_OK,
        message: "Información de cuenta obtenida correctamente",
        data: storedInfo,
      };
    }

    // Si no hay datos en localStorage, hacer la petición al servidor
    const response = await getAccountInfo_service();

    if (!response.success) {
      setAccountError("Error al obtener la información de la cuenta");
      return response;
    }

    const formattedResponse = {
      id: response.data.idEmployee,
      name: response.data.name,
      lastName: response.data.lastName,
      alias: response.data.alias,
      email: response.data.email,
      lounge: {
        id: response.data.localId,
        name: response.data.localName,
      },
      company: {
        id: response.data.companyId,
        name: response.data.companyName,
      },
      profilePicture: response.data.profileImage,
    };

    setAccountInfo(formattedResponse);

    return {
      isSuccessful: true,
      status: STATUS_OK,
      message: "Información de cuenta obtenida correctamente",
      data: formattedResponse,
    };
  } catch (error) {
    const errorMessage =
      "Error inesperado al obtener la información de la cuenta";
    setAccountError(errorMessage);
    return {
      isSuccessful: false,
      status: 500,
      message: errorMessage,
      data: null,
    };
  } finally {
    setIsLoadingAccount(false);
  }
};
