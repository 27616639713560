import axios from "axios";
import { oldBaseUrl, config } from "./constGlobals";

export const getAccountInfo_service = async (): Promise<IResponse> => {
  const url = `${oldBaseUrl}/Empleado/DatosGeneralesEmpleado`;

  try {
    const response = await axios.get(url, config).then((res) => res.data);
    return {
      success: true,
      status: response.status,
      user_message: response.user_message,
      developer_message: response.developer_message,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los datos del empleado. Por favor, intente de nuevo.",
      developer_message: null,
      data: [],
    };
  }
};
