import React, { useCallback } from "react";
import styles from "./CashregisterMovementsTable.module.css";
import { formatToSoles } from "@Utilities/FormatsHandler";
import CustomTable, {
  Column,
} from "@Components/ui/molecules/CustomTable/CustomTable";
import { getSaleBoxReportMovements } from "@Adapters/saleboxes/saleboxreport/getSaleBoxReport.adapter";
import { useSaleBoxReportStore } from "@Stores/saleBoxReportStore";
import { useNotificationStore } from "@Stores/notificationStore";

const CashregisterMovementsTable: React.FC = () => {
  const movements = useSaleBoxReportStore((state) => state.movements);
  const filters = useSaleBoxReportStore((state) => state.report);

  // console.log(filters);

  const handlePageChange = useCallback(
    async (page: number) => {
      const response = await getSaleBoxReportMovements({
        salebox: filters.salebox,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        page: page,
      });

      if (response.isSuccessful) {
        useSaleBoxReportStore.getState().updateReportMovements(response.data);
      } else {
        const updateNotification =
          useNotificationStore.getState().updateNotification;
        updateNotification({
          message: response.message,
          status: response.status,
          toastTimeDuration: 4000,
        });
      }
    },
    [filters]
  );

  const columns = [
    {
      header: "Fecha",
      accessor: (movement) => movement.date,
    },
    {
      header: "Hora",
      accessor: (movement) => movement.time,
    },
    {
      header: "Tipo",
      accessor: (movement) => movement.type,
    },
    {
      header: "Concepto",
      accessor: (movement) => movement.concept,
    },
    {
      header: "Método de pago",
      accessor: (movement) => movement.paymentMethod,
    },
    {
      header: "Ingreso",
      accessor: (movement) => {
        if (movement.isCashRegisterCloseMovement) {
          return formatToSoles(movement.income);
        }
        return movement.income > 0 ? formatToSoles(movement.income) : "-";
      },
      cellClassName: (movement) =>
        movement.isCashRegisterCloseMovement ? styles.mergedCell : "",
    },
    {
      header: "Egreso",
      accessor: (movement) => {
        if (movement.isCashRegisterCloseMovement) {
          return null;
        }
        return movement.outcome > 0 ? formatToSoles(movement.outcome) : "-";
      },
    },
    {
      header: "Total acumulado",
      accessor: (movement) => {
        if (movement.isCashRegisterCloseMovement) {
          return null;
        }
        return formatToSoles(movement.total);
      },
    },
  ];

  const processedData = movements.data?.map((movement) => ({
    ...movement,
    rowClassName: `${
      movement.isCashRegisterCloseMovement ? styles.closeMovementRow : ""
    } ${!movement.active ? styles.inactiveRow : ""}`,
  }));

  return (
    <CustomTable
      data={processedData || []}
      columns={columns as Column<{ id: string | number }>[]}
      currentPage={movements.currentPage}
      setCurrentPage={handlePageChange}
      totalPages={movements.totalPages}
      emptyMessage="No se han encontrado movimientos para este período."
    />
  );
};

export default CashregisterMovementsTable;
