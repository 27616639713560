import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IElectronicBilling {
  lastPurchaseDate: string | null;
  isRunningOutOfDocuments: boolean;
}

interface IWarehouse {
  id: number;
  products: any[];
  hasFetchedProducts: boolean;
}

interface SaleConfigState {
  account: IAccountInfo | null;
  billingDocuments: any[];
  electronicBillingAmmount: number | null;
  electronicBilling: IElectronicBilling;
  paymentMethods: any[];
  registeredClients: any[];
  companyServices: any[];
  packages: any[];
  clientAppointments: any[];
  warehouses: IWarehouse[];
  loungeEmployees: any[];

  updateAccount: (account: IAccountInfo) => void;
  updateBillingDocuments: (documents: any[]) => void;
  updateElectronicBillingAmmount: (amount: number) => void;
  updateElectronicBilling: (billing: IElectronicBilling) => void;
  updatePaymentMethods: (methods: any[]) => void;
  updateRegisteredClients: (clients: any[]) => void;
  updateCompanyServices: (services: any[]) => void;
  updateClientAppointments: (appointments: any[]) => void;
  updateWarehouses: (warehouses: IWarehouse[]) => void;
  updateWarehouseProducts: (data: {
    products: any[];
    warehouseId: number;
  }) => void;
  updateLoungeEmployees: (employees: any[]) => void;
  updatePackages: (packages: any[]) => void;
}

export const useSaleConfigStore = create<SaleConfigState>()(
  persist(
    (set) => ({
      account: null,
      billingDocuments: [],
      electronicBillingAmmount: null,
      electronicBilling: {
        lastPurchaseDate: null,
        isRunningOutOfDocuments: false,
      },
      paymentMethods: [],
      registeredClients: [],
      companyServices: [],
      packages: [],
      clientAppointments: [],
      warehouses: [],
      loungeEmployees: [],

      updateAccount: (account) => set(() => ({ account })),

      updateBillingDocuments: (billingDocuments) =>
        set(() => ({ billingDocuments })),

      updateElectronicBillingAmmount: (electronicBillingAmmount) =>
        set(() => ({ electronicBillingAmmount })),

      updateElectronicBilling: (electronicBilling) =>
        set(() => ({ electronicBilling })),

      updatePaymentMethods: (paymentMethods) => set(() => ({ paymentMethods })),

      updateRegisteredClients: (registeredClients) =>
        set(() => ({ registeredClients })),

      updateCompanyServices: (companyServices) =>
        set(() => ({ companyServices })),

      updateClientAppointments: (clientAppointments) =>
        set(() => ({ clientAppointments })),

      updateWarehouses: (warehouses) => set(() => ({ warehouses })),

      updateWarehouseProducts: ({ products, warehouseId }) =>
        set((state) => ({
          warehouses: state.warehouses.map((warehouse) =>
            warehouse.id === warehouseId
              ? {
                  ...warehouse,
                  products: products.length === 0 ? [] : products,
                  hasFetchedProducts: true,
                }
              : warehouse
          ),
        })),

      updateLoungeEmployees: (loungeEmployees) =>
        set(() => ({ loungeEmployees })),

      updatePackages: (packages) => set(() => ({ packages })),
    }),
    {
      name: "saleConfig-storage",
    }
  )
);
