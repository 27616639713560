import React, { useEffect } from "react";
import { getLounges } from "@Adapters/getLounges.adapter";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";
import {
  IProductsReportStore,
  useProductsReportStore,
} from "@Stores/useProductsReportStore";
import { getProductsReport } from "@Adapters/getProductsReport.adapter";

const useLoadProductReport = () => {
  const { loungesFetchResponse, setLoungesFetchResponse, lounges, setLounges } =
    useLoungesStore() as ILoungesStore;
  const {
    filter,
    setFilter,
    currentPage,
    setCurrentPage,
    pages,
    setPages,
    productsFetchResponse,
    setProductsFetchResponse,
    setProducts,
    setTopProducts,
    setPiegraphProductsData,
  } = useProductsReportStore() as IProductsReportStore;
  const { setToaster } = useToasterStore() as IToasterStore;

  const loadProductReport = async (tempFilter?) => {
    const localFilter = filter.loungeId ? filter : tempFilter;

    setProductsFetchResponse({
      status: "loading",
      message: null,
    });
    const payload = { ...localFilter, page: currentPage };
    const setters = {
      setToaster,
      setPages,
      setProducts,
      setTopProducts,
      setPiegraphProductsData,
      setProductsFetchResponse,
    };
    await getProductsReport(setters, payload);
  };

  const setInitialFilter = async (localLounges) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const formattedLastDate = `${currentDate.getFullYear() - 1}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const loungeId = localLounges[0]?.value;
    const newValues = {
      loungeId: loungeId || "",
      fromDate: formattedLastDate,
      toDate: formattedDate,
    };
    setFilter(newValues);

    return newValues;
  };

  useEffect(() => {
    if (loungesFetchResponse.status !== "success") {
      (async () => {
        setLoungesFetchResponse({
          status: "loading",
          message: null,
        });
        const response = await getLounges(setToaster);
        if (response.success) {
          setLounges(response.data);
          const initialFilter = await setInitialFilter(response.data);
          await loadProductReport(initialFilter);
        }
        setLoungesFetchResponse({
          status: response.success ? "success" : "error",
          message: response.user_message,
        });
      })();
    } else {
      (async () => {
        const initialFilter = await setInitialFilter(lounges);
        if (productsFetchResponse.status !== "success")
          loadProductReport(initialFilter);
      })();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loadProductReport };
};

export default useLoadProductReport;
