import React, { useContext } from "react";
import style from "./WarehousesReport.module.css";
// @ts-ignore
import { HeaderNavigation, LoadingScreen } from "@viuti/recursos";
import { navigateTo } from "@Utilities/navigate";
import { useNavigate } from "react-router-dom";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import {
  useWarehousesReportStore,
  IWarehousesReportStore,
} from "@Stores/useWarehousesReportStore";
import useLoadWarehouseReport from "@Hooks/useLoadWarehouseReport";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import WarehouseReportFilter from "@Components/ui/molecules/WarehouseReportFilter/WarehouseReportFilter";
import WarehousesTable from "@Components/ui/organisms/WarehousesTable/WarehousesTable";
import useExportWarehousesReport from "@Components/ui/organisms/ExportWarehousesReportModal/hooks/useExportWarehousesReport";
import { FILE_EXTENSION } from "@Models/interfaces/reports";

const WarehousesReport = () => {
  const { loadWarehouseReport } = useLoadWarehouseReport();
  const navigate = useNavigate();
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  // const { loungesFetchResponse } = useLoungesStore() as ILoungesStore;
  const { warehousesFetchResponse } =
    useWarehousesReportStore() as IWarehousesReportStore;

  const { onSubmit, fetchResponses } = useExportWarehousesReport({
    modalContext,
  });

  return (
    <div id="viuti-front-mainContent">
      <div className={style.view__container}>
        <HeaderNavigation
          title={"Reporte de movimientos de almacenes"}
          previousAction={() => navigateTo(navigate, "/")}
          previousActionMovement={"back"}
          buttonProps={{
            textBttn: "Exportar CSV",
            handleClick: () => onSubmit({ shareType: FILE_EXTENSION.CSV }),
            isDisabled: fetchResponses[FILE_EXTENSION.CSV]?.loading,
            isLoading: fetchResponses[FILE_EXTENSION.CSV]?.loading,
            isPrimary: true,
            isHidden: false,
          }}
        />
        {warehousesFetchResponse.status === "loading" ? (
          <LoadingScreen visible={true} />
        ) : (
          <>
            <h3 className={style.view__instructions}>
              Visualiza y analiza los datos de tus almacenes para mejorar la
              gestión de inventario y la toma de decisiones en tu negocio.
            </h3>
            <div className={style.view__content}>
              <WarehouseReportFilter loadReportAction={loadWarehouseReport} />
              <div className={style.report_container}>
                <div
                  className={`${style.card__container} ${style.pie_graph__container}`}
                >
                  <h4 className={style.title}>Movimientos de almacén</h4>
                  <WarehousesTable />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WarehousesReport;
