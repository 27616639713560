import { exportServiceReport_service } from "@Services/services";
import { downloadCSV } from "@Utilities/DownloadFile";

export const exportServiceReport = async (
  shareType: string,
  loungeId: string
) => {
  let response: IResponse;

  switch (shareType) {
    case "csv":
      response = await exportServiceReport_service(loungeId);

      if (!response.success) {
        return response;
      }

      let file = {
        file: `data:application/pdf;base64,${response.data}`,
        file_name: "Reporte de servicios",
      };
      downloadCSV(file);
      return response;
    // case "xml":
    //   response = {
    //     success: true,
    //     data: null,
    //     status: 200,
    //     user_message: "Reporte exportado correctamente.",
    //     developer_message: null,
    //   };
    //   break;

    default:
      break;
  }

  return response;
};
