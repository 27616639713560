import React from "react";
import style from "./PieGraphBuilder.module.css";
import { PieChart } from "@mui/x-charts/PieChart";
import {
  DefaultizedPieValueType,
  HighlightItemData,
  pieArcLabelClasses,
  PieValueType,
} from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";

interface IPieChart {
  data: MakeOptional<PieValueType, "id">[];
  onArcClick: (topServiceIndex: number) => void;
}

const PieGraphBuilder: React.FC<IPieChart> = ({ data, onArcClick }) => {
  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const handleArcClick = (item) => {
    onArcClick(item);
  };

  return (
    <div className={style.graph__content}>
      <PieChart
        onItemClick={(e, item) => handleArcClick(item)}
        onHighlightChange={(item: HighlightItemData | null) =>
          handleArcClick(item)
        }
        series={[
          {
            data: data,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: "gray",
            },
            arcLabel: getArcLabel,
            arcLabelMinAngle: 25,
            innerRadius: 20,
            outerRadius: 140,
            paddingAngle: 1,
            cornerRadius: 5,
            startAngle: 0,
            endAngle: 1000,
            cx: 150,
            cy: 150,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontSize: 14,
          },
        }}
        slotProps={{
          legend: { hidden: true },
        }}
      />
    </div>
  );
};

export default PieGraphBuilder;
