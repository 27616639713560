import {
  IWarehouse,
  IWarehousesFilter,
  IPieGraphData,
  IWarehouseOption,
} from "@Models/interfaces/warehouses";
import { create } from "zustand";

export interface IWarehousesReportStore {
  filter: IWarehousesFilter;
  setFilter: (filter: IWarehousesFilter) => void;
  warehouses: IWarehouse[];
  setWarehouses: (warehouses: IWarehouse[]) => void;
  warehousesList: IWarehouseOption[];
  setWarehousesList: (warehousesList: IWarehouseOption[]) => void;
  warehousesFetchResponse: IFetchReportResponse;
  setWarehousesFetchResponse: (response: IFetchReportResponse) => void;
}

export const useWarehousesReportStore = create((set) => ({
  filter: {
    warehouseId: "",
    fromDate: "",
    toDate: "",
    warehouseName: "",
    sales: 0,
  },
  setFilter: (filter: IWarehousesFilter) => set({ filter }),
  warehouses: [],
  setWarehouses: (warehouses: IWarehouse[]) => set({ warehouses }),
  warehousesList: [],
  setWarehousesList: (warehousesList: IWarehouseOption[]) =>
    set({ warehousesList }),
  warehousesFetchResponse: {
    status: "loading",
    message: "",
  },
  setWarehousesFetchResponse: (response: IFetchReportResponse) =>
    set({ warehousesFetchResponse: response }),
}));
