import { ROUTES } from "@Models/routes";
import { Route, Routes } from "react-router-dom";
import { InitialView } from "@Pages/InitialView/InitialView";
import {
  NotFoundPage,
  ModalAcceptCancel,
  SlideNotification,
  // @ts-ignore
} from "@viuti/recursos";
import { IToasterStore, useToasterStore } from "../store/useToasterStore";
import ServicesReport from "@Pages/ServicesReport/ServicesReport";
import { useContext } from "react";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import ProductsReport from "@Pages/ProductsReport/ProductsReport";
import ClientsReport from "@Pages/ClientsReport/ClientsReport";
import WarehousesReport from "@Pages/WarehousesReport/WarehousesReport";
import SaleMovements from "@Pages/SaleMovements/SaleMovements";
import SaleDocuments from "@Pages/SaleDocuments/SaleDocuments";
import SaleboxReport from "@Pages/SaleboxReport/SaleboxReport";

const HomeRoute = () => {
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;
  // Slide Notification
  const { message, status, loading, setEmpty } =
    useToasterStore() as IToasterStore;

  return (
    <>
      <Routes>
        <Route path={ROUTES.HOME_ROUTE} element={<InitialView />} />
        <Route
          path={ROUTES.SERVICES_REPORTS_ROUTE}
          element={<ServicesReport />}
        />
        <Route
          path={ROUTES.PRODUCTS_REPORTS_ROUTE}
          element={<ProductsReport />}
        />
        <Route
          path={ROUTES.CLIENTS_REPORTS_ROUTE}
          element={<ClientsReport />}
        />
        <Route
          path={ROUTES.WAREHOUSES_REPORT_ROUTE}
          element={<WarehousesReport />}
        />
        <Route path={ROUTES.SALE_MOVEMENTS_ROUTE} element={<SaleMovements />} />
        <Route path={ROUTES.SALE_DOCUMENTS_ROUTE} element={<SaleDocuments />} />
        <Route path={ROUTES.MY_SALEBOX_ROUTE} element={<SaleboxReport />} />
        <Route path="*" element={<NotFoundPage />}></Route>
      </Routes>
      <ModalAcceptCancel
        {...modalContext}
        buttonOne={{
          textButtonOne: "Programar envío",
          actionButtonOne: () => {},
          isDisabled: true,
          isLoading: false,
          isHidden: true,
        }}
      >
        {modalContext.modalChildren}
      </ModalAcceptCancel>
      <SlideNotification
        state={{
          message: message,
          status: status,
          loading: loading,
        }}
        clearStatus={() => setEmpty()}
      />
    </>
  );
};

export default HomeRoute;
