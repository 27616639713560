import { IEmployee } from "@Models/interfaces/employees";
import {
  IMovement,
  ISimpleConcept,
  IConcept,
} from "@Models/interfaces/saleboxes";
import { create } from "zustand";

interface ISalesMovementsStore {
  // Estado de conceptos
  concepts: IConcept[];
  entryConcepts: ISimpleConcept[];
  outputConcepts: ISimpleConcept[];
  isLoadingConcepts: boolean;

  // Estado de movimientos
  movements: IMovement[];
  currentPage: number;
  totalPages: number;
  shouldReloadTable: boolean;
  employees: IEmployee[];
  isLoadingEmployees: boolean;
  isLoadingMovements: boolean;
  error: string | null;

  // Acciones para conceptos
  setConcepts: (concepts: IConcept[]) => void;
  setEntryConcepts: (concepts: ISimpleConcept[]) => void;
  setOutputConcepts: (concepts: ISimpleConcept[]) => void;
  addConcept: (concept: IConcept) => void;
  removeConcept: (conceptValue: number) => void;
  setIsLoadingConcepts: (isLoading: boolean) => void;
  clearConcepts: () => void;

  // Acciones para movimientos
  setMovements: (movements: IMovement[]) => void;
  updateMovement: (
    movementId: number,
    updatedMovement: Partial<IMovement>
  ) => void;
  clearMovements: () => void;
  setIsLoadingMovements: (isLoading: boolean) => void;
  setShouldReloadTable: (shouldReload: boolean) => void;

  // Acciones para paginación
  setCurrentPage: (page: number) => void;
  setTotalPages: (pages: number) => void;
  nextPage: () => void;
  previousPage: () => void;

  // Acciones para empleados
  setEmployees: (employees: IEmployee[]) => void;
  setIsLoadingEmployees: (isLoading: boolean) => void;
  setError: (error: string | null) => void;

  // Acción para limpiar todo el store
  clearStore: () => void;

  // Nueva acción para crear conceptos
  onConceptCreation: (newConcept: IConcept) => void;
}

const initialState = {
  // Estado inicial de conceptos
  concepts: [],
  entryConcepts: [],
  outputConcepts: [],
  isLoadingConcepts: false,

  // Estado inicial del resto
  movements: [],
  currentPage: 1,
  totalPages: 1,
  shouldReloadTable: false,
  employees: [],
  isLoadingEmployees: false,
  isLoadingMovements: false,
  error: null,
};

export const useSalesMovementsStore = create<ISalesMovementsStore>((set) => ({
  ...initialState,

  // Acciones para conceptos
  setConcepts: (concepts) => set({ concepts }),
  setEntryConcepts: (entryConcepts) => set({ entryConcepts }),
  setOutputConcepts: (outputConcepts) => set({ outputConcepts }),
  addConcept: (newConcept) =>
    set((state) => ({
      concepts: [...state.concepts, newConcept],
      entryConcepts:
        newConcept.movementType === 1
          ? [
              ...state.entryConcepts,
              { name: newConcept.name, value: newConcept.value.toString() },
            ]
          : state.entryConcepts,
      outputConcepts:
        newConcept.movementType === 2
          ? [
              ...state.outputConcepts,
              { name: newConcept.name, value: newConcept.value.toString() },
            ]
          : state.outputConcepts,
    })),
  removeConcept: (conceptValue) =>
    set((state) => ({
      concepts: state.concepts.filter(
        (concept) => concept.value !== conceptValue
      ),
      entryConcepts: state.entryConcepts.filter(
        (concept) => Number(concept.value) !== conceptValue
      ),
      outputConcepts: state.outputConcepts.filter(
        (concept) => Number(concept.value) !== conceptValue
      ),
    })),
  setIsLoadingConcepts: (isLoading) => set({ isLoadingConcepts: isLoading }),
  clearConcepts: () =>
    set({ concepts: [], entryConcepts: [], outputConcepts: [] }),

  // Acciones para movimientos
  setMovements: (movements) => set({ movements }),
  updateMovement: (movementId, updatedMovement) =>
    set((state) => ({
      movements: state.movements.map((movement) =>
        movement.id === movementId
          ? { ...movement, ...updatedMovement }
          : movement
      ),
    })),
  clearMovements: () => set({ movements: [] }),
  setIsLoadingMovements: (isLoading) => set({ isLoadingMovements: isLoading }),
  setShouldReloadTable: (shouldReload) =>
    set({ shouldReloadTable: shouldReload }),

  // Acciones para paginación
  setCurrentPage: (page) => set({ currentPage: page }),
  setTotalPages: (pages) => set({ totalPages: pages }),
  nextPage: () =>
    set((state) => ({
      currentPage:
        state.currentPage < state.totalPages
          ? state.currentPage + 1
          : state.currentPage,
    })),
  previousPage: () =>
    set((state) => ({
      currentPage:
        state.currentPage > 1 ? state.currentPage - 1 : state.currentPage,
    })),

  // Acciones para empleados
  setEmployees: (employees) => set({ employees }),
  setIsLoadingEmployees: (isLoading) => set({ isLoadingEmployees: isLoading }),
  setError: (error) => set({ error }),

  // Limpiar todo el store
  clearStore: () => set(initialState),

  // Nueva acción para crear conceptos
  onConceptCreation: (newConcept) =>
    set((state) => {
      const simpleConcept = {
        name: newConcept.name,
        value: newConcept.value.toString(),
      };

      return {
        concepts: [...state.concepts, newConcept],
        entryConcepts:
          newConcept.movementType === 1
            ? [...state.entryConcepts, simpleConcept]
            : state.entryConcepts,
        outputConcepts:
          newConcept.movementType === 2
            ? [...state.outputConcepts, simpleConcept]
            : state.outputConcepts,
        shouldReloadTable: true,
      };
    }),
}));
