import { exportServiceReport } from "@Adapters/exportServiceReport.adapter";
import {
  IServicesReportStore,
  useServicesReportStore,
} from "@Stores/useServicesReportStore";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";
import React, { useEffect, useState } from "react";

const useExportServicesReport = ({ modalContext }) => {
  const { setToaster } = useToasterStore() as IToasterStore;
  const { filter } = useServicesReportStore() as IServicesReportStore;
  const [fetchResponses, setFetchResponses] = useState({
    download: { status: null, message: null, loading: false, error: false },
  });
  const [lastFetchType, setLastFetchType] = useState(null);

  const lastFetch: {
    status: number;
    message: string;
    loading: boolean;
    error: boolean;
  } = fetchResponses[lastFetchType];

  const isCsvPending = lastFetchType === "csv" && lastFetch.loading;
  // const isXmlPending = lastFetchType === "xml" && lastFetch.loading;

  const updateFetchResponses = (
    type: string,
    status: number,
    message: string,
    loading: boolean,
    error: boolean
  ) => {
    setLastFetchType(type);
    setFetchResponses((prev) => {
      return {
        ...prev,
        [type]: {
          status: status,
          message: message,
          loading: loading,
          error: error,
        },
      };
    });
  };

  const onSubmit = async ({ shareType }) => {
    updateFetchResponses(shareType, null, null, true, false);

    const response = await exportServiceReport(shareType, filter.loungeId);

    updateFetchResponses(
      shareType,
      response.status,
      response.user_message,
      false,
      false
    );
    setToaster(response.user_message, response.status, false);
  };

  useEffect(() => {
    modalContext.handleActionButtonCloseChange(() => {
      setFetchResponses({
        download: {
          status: null,
          message: null,
          loading: false,
          error: false,
        },
      });
      setLastFetchType(null);
      modalContext.handleClose();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFetch]);

  return {
    onSubmit,
    fetchResponses,
    isCsvPending,
  };
};

export default useExportServicesReport;
