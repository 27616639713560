import { IClient } from "@Models/interfaces/clients";
import { getClients_service } from "@Services/clients";

export const getClientsReport = async (
  setters: {
    setPages: (pages: number) => void;
    setToaster: (
      message: string,
      status: number,
      loading?: boolean,
      toastTimeDuration?: number | string
    ) => void;
    setClients: (clients: IClient[]) => void;
    setClientsFetchResponse: (response: IFetchReportResponse) => void;
  },
  payload: {
    loungeId: string;
    categoryId: string;
    dateFilterType: number;
    fromDate: string;
    toDate: string;
    page: number;
  }
) => {
  const { setToaster, setClients, setClientsFetchResponse, setPages } = setters;

  const response: IResponse = await getClients_service(payload);

  setClientsFetchResponse({
    status: response.success ? "success" : "error",
    message: response.user_message,
  });

  if (!response.success) {
    setToaster(response.user_message, response.status, false, 4000);
    return response;
  }

  const clients: IClient[] = response.data.clients.map((client) => {
    return {
      id: client.id,
      name: client.name,
      lastVisitDate: client.lastVisitDate,
      visitCount: client.visitCount,
      totalSpent: client.totalSpent,
      birthday: client.birthday,
      category: client.category,
    };
  });

  setClients(clients);
  setPages(response.data.totalPages);
};
