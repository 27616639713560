import axios from "axios";
import { saleboxesBaseUrl, config } from "./constGlobals";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getSalesDocuments_service = async (
  payload
): Promise<IResponse> => {
  const url = `${saleboxesBaseUrl}/SalesDocuments/SalesDocuments?searParameter=${payload.searchParameter}&idBox=${payload.idBox}&typeDocumetnId=${payload.typeDocumentId}&status=${payload.status}&statusFE=${payload.statusFE}&startDate=${payload.startDate}&endDate=${payload.endDate}&page=${payload.page}`;
  const carga_documentos_venta = 65;
  trackUserAction(carga_documentos_venta);
  try {
    const response = await axios.get(url, config);
    return {
      success: true,
      status: response.status,
      user_message: response.data.message,
      developer_message: null,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los documentos de venta. Intentalo de nuevo.",
      developer_message: null,
      data: [],
    };
  }
};
