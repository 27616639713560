import { formatHtmlToBlob } from "@Components/ui/organisms/ShareSaleboxReportModal/functions/formatHtmlToBlob";
import { useNotificationStore } from "@Stores/notificationStore";
import {
  downloadSaleboxReport_service,
  postSendEmailSaleboxReport_service,
} from "@Services/reports";

export const postShareReport = async (shareType: string, payload: any) => {
  let response;

  switch (shareType) {
    case "email":
      const currentDate = new Date()
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      const documentName = `Reporte_${currentDate}.pdf`;
      response = await postSendEmailSaleboxReport_service(
        await formatHtmlToBlob(payload, documentName)
      );
      break;
    case "download":
      const updateNotification =
        useNotificationStore.getState().updateNotification;
      updateNotification({
        message: "Descarga del reporte en proceso, por favor espera.",
        status: "pending",
        toastTimeDuration: 4000,
      });
      response = await downloadSaleboxReport_service(payload);
      if (response.isSuccessful) {
        const link = document.createElement("a");
        const base64String = response.data.base64String;
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
        link.download = "reporte_caja.xlsx";
        link.click();
        updateNotification({
          message:
            "Descarga del reporte exitosa, revisa tu carpeta de descargas.",
          status: 200,
          toastTimeDuration: 8000,
        });
      }
      break;
    default:
      break;
  }

  return response;
};
