import React, { useContext, useState } from "react";
import style from "./ServicesReport.module.css";
// @ts-ignore
import { HeaderNavigation, LoadingScreen } from "@viuti/recursos";
import { navigateTo } from "@Utilities/navigate";
import { useNavigate } from "react-router-dom";
import PieGraphBuilder from "@Components/ui/molecules/PieGraphBuilder/PieGraphBuilder";
import ServiceReportFilter from "@Components/ui/molecules/ServiceReportFilter/ServiceReportFilter";
import { ILoungesStore, useLoungesStore } from "@Stores/useLoungesStore";
import {
  useServicesReportStore,
  IServicesReportStore,
} from "@Stores/useServicesReportStore";
import useLoadServiceReport from "@Hooks/useLoadServiceReport";
import TopServicesList from "@Components/ui/molecules/TopServicesList/TopServicesList";
import ServicesTable from "@Components/ui/organisms/ServicesTable/ServicesTable";

import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import { SERVICE_REPORT_NAME } from "@Models/consts";
import { changeModalExport } from "@Hooks/changeModalExport";
import useExportServicesReport from "@Components/ui/organisms/ExportServicesReportModal/hooks/useExportServicesReport";
import { FILE_EXTENSION } from "@Models/interfaces/reports";

const ServicesReport = () => {
  const { loadServiceReport } = useLoadServiceReport();
  const navigate = useNavigate();
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  const { loungesFetchResponse } = useLoungesStore() as ILoungesStore;
  const { servicesFetchResponse, piegraphServicesData, topServices } =
    useServicesReportStore() as IServicesReportStore;

  const [highlightedTopService, setHighlightedTopService] = useState(null);

  const handleArcClick = (item) => {
    if (item === null) return setHighlightedTopService(null);
    if (item?.dataIndex !== null) {
      const highlightedService = topServices[item?.dataIndex];
      setHighlightedTopService(highlightedService);
    }
  };

  const { onSubmit, fetchResponses } = useExportServicesReport({
    modalContext,
  });

  return (
    <div id="viuti-front-mainContent">
      <div className={style.view__container}>
        <HeaderNavigation
          title={"Reporte de servicios"}
          previousAction={() => navigateTo(navigate, "/")}
          previousActionMovement={"back"}
          buttonProps={{
            textBttn: "Exportar CSV",
            handleClick: () => onSubmit({ shareType: FILE_EXTENSION.CSV }),
            // changeModalExport(SERVICE_REPORT_NAME, modalContext),
            isDisabled: fetchResponses[FILE_EXTENSION.CSV]?.loading,
            isLoading: fetchResponses[FILE_EXTENSION.CSV]?.loading,
            isPrimary: true,
            isHidden: false,
          }}
        />
        {loungesFetchResponse.status === "loading" ||
        servicesFetchResponse.status === "loading" ? (
          <LoadingScreen visible={true} />
        ) : (
          <>
            <h3 className={style.view__instructions}>
              Visualiza y analiza los datos de ventas de servicios para mejorar
              la toma de decisiones en tu negocio. Todos los precios mostrados
              incluyen sus impuestos correspondientes.
            </h3>
            <div className={style.view__content}>
              <ServiceReportFilter loadReportAction={loadServiceReport} />
              <div className={style.report_container}>
                <div
                  className={`${style.card__container} ${style.pie_graph__container}`}
                >
                  <h4 className={style.title}>Servicios mas vendidos</h4>
                  <div className={style.graph__container}>
                    <PieGraphBuilder
                      data={piegraphServicesData}
                      onArcClick={handleArcClick}
                    />
                    <TopServicesList
                      highlightedService={highlightedTopService}
                    />
                  </div>
                </div>

                <div
                  className={`${style.card__container} ${style.pie_graph__container}`}
                >
                  <h4 className={style.title}>Mis servicios</h4>
                  <ServicesTable />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ServicesReport;
