// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rLzMZ5gbyoy558_ZIuhC {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  gap: 24px;
}

._KOE8Py8tVblfERk2lFb {
  font-weight: 600;
  font-size: var(--font-size-reading);
}

.yZD65HpARSLA0QGN5MqI {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

._1E_pfwUF59A_5lZFydaC {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.ygzgiE89B3uiqvyt2e66 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}

.zjZwSD5cSqrvRlHLW7jU {
  width: 100%;
}

.w9k0pEmyBCFb3xG_lBtf {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  gap: 2rem;

  @media screen and (width <= 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.jnvP8RZXOiBpCuAzNiIR {
  font-size: 16px;
  color: #45348e;
}
`, "",{"version":3,"sources":["webpack://./src/pages/WarehousesReport/WarehousesReport.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,SAAS;;EAET;IACE,sBAAsB;IACtB,mBAAmB;EACrB;AACF;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".view__container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow-y: auto;\n  gap: 24px;\n}\n\n.view__instructions {\n  font-weight: 600;\n  font-size: var(--font-size-reading);\n}\n\n.view__content {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.report_container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 2rem;\n}\n\n.card__container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.pie_graph__container {\n  width: 100%;\n}\n\n.graph__container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  padding: 20px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  border: 1px solid #e5e5e5;\n  gap: 2rem;\n\n  @media screen and (width <= 768px) {\n    flex-direction: column;\n    align-items: center;\n  }\n}\n\n.title {\n  font-size: 16px;\n  color: #45348e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view__container": `rLzMZ5gbyoy558_ZIuhC`,
	"view__instructions": `_KOE8Py8tVblfERk2lFb`,
	"view__content": `yZD65HpARSLA0QGN5MqI`,
	"report_container": `_1E_pfwUF59A_5lZFydaC`,
	"card__container": `ygzgiE89B3uiqvyt2e66`,
	"pie_graph__container": `zjZwSD5cSqrvRlHLW7jU`,
	"graph__container": `w9k0pEmyBCFb3xG_lBtf`,
	"title": `jnvP8RZXOiBpCuAzNiIR`
};
export default ___CSS_LOADER_EXPORT___;
