import { STATUS_OK } from "@Models/httpsStatus";
import { v4 as uuid } from "uuid";
import {
  getSaleBoxReport_service,
  getSaleBoxReportMovements_service,
} from "@Services/saleBox";
import { ISaleBox } from "@Models/interfaces/saleboxes";
import { useNotificationStore } from "@Stores/notificationStore";
import { useSaleBoxReportStore } from "@Stores/saleBoxReportStore";

export const getSaleBoxReport = async (values: {
  salebox: ISaleBox | "";
  fromDate: string;
  toDate: string;
}) => {
  const updateNotification = useNotificationStore.getState().updateNotification;
  const updateReport = useSaleBoxReportStore.getState().updateReport;
  const updateReportMovements =
    useSaleBoxReportStore.getState().updateReportMovements;

  const payload = {
    idSalebox: values.salebox !== "" ? values.salebox.id : 0,
    startDate: values.fromDate,
    endDate: values.toDate,
  };

  // Obtener el reporte principal
  const response = await getSaleBoxReport_service(payload);

  if (response.status !== STATUS_OK) {
    updateReport({
      isSuccessful: false,
      status: response.status,
      message: "Error al obtener el reporte de caja",
      data: null,
    });
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    });
    return response;
  }

  // Obtener los movimientos del reporte
  const movementsPayload = {
    ...payload,
    page: 1,
  };

  const movementsResponse = await getSaleBoxReportMovements_service(
    movementsPayload
  );

  if (movementsResponse.status !== STATUS_OK) {
    updateNotification({
      message: "Error al obtener los movimientos del reporte",
      status: movementsResponse.status,
      toastTimeDuration: 4000,
    });
  }

  const data = response.data;
  const formattedResponse = {
    pettyCash: {
      currentAmount: data.saleBoxCash,
      totalIncome: data.entryCash,
      totalOutcome: data.outputCash,
    },
    movements: {
      sales: {
        amount: data.totalQuantitySales,
        totalCash: data.totalCashSales,
      },
      inputs: {
        amount: data.entryQuantity,
        totalCash: data.totalEntryCash,
      },
      outputs: {
        amount: data.outputQuantity,
        totalCash: data.totalOutputCash,
      },
    },
    payments:
      data.paymentMethods.length > 0
        ? data.paymentMethods.map((method) => ({
            category: method.category,
            subCategory: method.subcategory,
            amount: method.amount,
          }))
        : [],
  };

  updateReport({
    isSuccessful: true,
    status: STATUS_OK,
    message: "Reporte de caja obtenido correctamente",
    data: formattedResponse,
  });

  if (movementsResponse.status === STATUS_OK) {
    const formattedMovements = {
      data: movementsResponse.data.detail.map((item: any) => {
        // Separar la fecha y hora del string
        const [datePart, timePart, meridiem] = item.date.split(" ");

        // Función auxiliar para capitalizar texto de manera segura
        const capitalizeText = (text: string | null | undefined) => {
          if (!text) return "";
          return `${text.charAt(0).toUpperCase()}${text
            .slice(1)
            .toLowerCase()}`;
        };

        return {
          id: uuid(),
          date: datePart,
          time: `${timePart} ${meridiem}`,
          isCashRegisterCloseMovement: item.closeMovement,
          type: capitalizeText(item.typeMovement),
          income: item.income,
          outcome: item.revenue,
          total: item.cumulative,
          concept: capitalizeText(item.description),
          paymentMethod: capitalizeText(item.paymentType),
          active: item.activo,
        };
      }),
      totalPages: movementsResponse.data.maxPage,
      currentPage: movementsResponse.data.actualPage,
    };

    updateReportMovements(formattedMovements);
  }

  return {
    status: STATUS_OK,
    message: "Reporte de caja obtenido correctamente",
    data: response.data,
  };
};

export const getSaleBoxReportMovements = async (values: {
  salebox: ISaleBox | "";
  fromDate: string;
  toDate: string;
  page: number;
}) => {
  const payload = {
    idSalebox: values.salebox !== "" ? values.salebox.id : 0,
    startDate: values.fromDate,
    endDate: values.toDate,
    page: values.page,
  };

  const movementsResponse = await getSaleBoxReportMovements_service(payload);

  if (movementsResponse.status !== STATUS_OK) {
    return {
      status: movementsResponse.status,
      message: "Error al obtener los movimientos del reporte",
      data: null,
    };
  }

  const formattedMovements = {
    data: movementsResponse.data.detail.map((item: any) => {
      const [datePart, timePart, meridiem] = item.date.split(" ");

      // Función auxiliar para capitalizar texto de manera segura
      const capitalizeText = (text: string | null | undefined) => {
        if (!text) return "";
        return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
      };

      return {
        id: uuid(),
        date: datePart,
        time: `${timePart} ${meridiem}`,
        isCashRegisterCloseMovement: item.closeMovement,
        type: capitalizeText(item.typeMovement),
        income: item.income,
        outcome: item.revenue,
        total: item.cumulative,
        concept: capitalizeText(item.description),
        paymentMethod: capitalizeText(item.paymentType),
        active: item.activo,
      };
    }),
    totalPages: movementsResponse.data.maxPage,
    currentPage: movementsResponse.data.actualPage,
  };

  return {
    isSuccessful: true,
    status: STATUS_OK,
    message: "Movimientos obtenidos correctamente",
    data: formattedMovements,
  };
};
