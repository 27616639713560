import React, { useContext, useState } from "react";
import style from "./ReportsList.module.css";
// @ts-ignore
import { HeaderNavigation } from "@viuti/recursos";
import { changeModalType } from "@Hooks/changeModalType/changeModalType";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import { navigateTo } from "@Utilities/navigate";
import { useNavigate } from "react-router-dom";

interface IReportsList {
  reports: IReport[];
}

const ReportsList = ({ reports }: IReportsList) => {
  const navigate = useNavigate();
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  const [searchInput, setSearchInput] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);

  const renderResults: IReport[] =
    searchInput === "" ? reports : searchedResults;

  const searchResults = (search: string) => {
    setSearchInput(search);
    const filteredReports = reports.filter((report) =>
      report.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setSearchedResults(filteredReports);
  };

  return (
    <div className={style.view__container}>
      <HeaderNavigation
        title={"Reportes"}
        previousAction={false}
        previousActionMovement={"close"}
        buttonProps={{
          textBttn: "Programar reporte",
          handleClick: () => changeModalType(null, modalContext),
          isDisabled: false,
          isPrimary: true,
          isHidden: true,
        }}
      />
      {/* <div className={style.search_container}>
				<SearchWithColoredBorder
					value={searchInput}
					placeholder={'Buscar reporte...'}
					setValue={searchResults}
					allData={reports}
					searchBy={['name']}
					clearInput={false}
				/>
			</div> */}
      <div className={style.report_list}>
        {renderResults.length > 0 ? (
          renderResults.map((report, index) => {
            const uniqueKey = `${report.name}-${index}`;
            return (
              <button
                key={uniqueKey}
                className={style.report_element}
                onClick={() => navigateTo(navigate, report.location)}
              >
                <h3 className={style.report_element_title}>{report.name}</h3>
                <p className={style.report_element_description}>
                  {report.description}
                </p>
              </button>
            );
          })
        ) : (
          <p>No se encontraron resultados</p>
        )}
      </div>
    </div>
  );
};

export default ReportsList;
