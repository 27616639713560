import { getSaleBoxes } from "@Adapters/saleboxes/getSaleBoxes.adapter";
import { useToasterStore, IToasterStore } from "@Stores/useToasterStore";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const useGetSaleBoxesOptions = () => {
  const { setToaster } = useToasterStore.getState() as IToasterStore;
  const [boxes, setBoxes] = React.useState([]);
  const { saleBoxId } = useParams();
  const currentBoxId = saleBoxId || "";

  useEffect(() => {
    (async () => {
      // Se obtienen las cajas de venta
      const boxes = await getSaleBoxes();
      if (boxes.status === 200) {
        // se adaptan las cajas de venta para el select
        const adaptedBoxes = boxes.data.map((box) => {
          return {
            name:
              box.name.length > 17 ? box.name.slice(0, 17) + "..." : box.name,
            value: box.id,
          };
        });
        // Se setean las cajas de venta
        setBoxes(adaptedBoxes);
      } else {
        setToaster(
          "Hubo un error al obtener las cajas. Intentalo de nuevo.",
          400,
          false
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { boxes, currentBoxId };
};

export default useGetSaleBoxesOptions;
