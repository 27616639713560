// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e_qd8KQ0PmJgFDNtrPuC {
  min-width: 310px;
  height: 310px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/PieGraphBuilder/PieGraphBuilder.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".graph__content {\n  min-width: 310px;\n  height: 310px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graph__content": `e_qd8KQ0PmJgFDNtrPuC`
};
export default ___CSS_LOADER_EXPORT___;
