import { getLounges_service } from "@Services/lounges";
import { LOUNGES_MOCKS } from "../_mocks_/lounges";

export const getLounges = async (
  setToaster: (message: string, status: number, loading: boolean) => void
) => {
  const response: IResponse = await getLounges_service();
  // const response: IResponse = {
  //   isSuccess: true,
  //   status: 200,
  //   message: null,
  //   data: LOUNGES_MOCKS,
  // };

  if (!response.success) {
    setToaster(response.user_message, response.status, false);
    return response;
  }

  const formattedResponse = response.data.map((lounge: any) => {
    return {
      value: lounge.lounge,
      name: lounge.nameLounge,
    };
  });

  return { ...response, data: formattedResponse };
};
