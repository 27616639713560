// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R2jtDw1gUlUyWnpYxdEv {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  gap: 24px;
}

.zlhPqiY0p31HQNfbormr {
  font-weight: 600;
  font-size: var(--font-size-reading);
}

.i7yC4YG4PFN63sOe9mxn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.zppNdook8MfbvwZQAcDc {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.vURt2LncN8qk6hNcFLZX {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}

.N4J8TGr7hdW90slC8vzG {
  width: 100%;
}

.s8_9wjPZ4pH51z0ETjFK {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  gap: 2rem;

  @media screen and (width <= 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.Cwv4IZCyIKPUE7OkJyvd {
  font-size: 16px;
  color: #45348e;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClientsReport/ClientsReport.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,SAAS;;EAET;IACE,sBAAsB;IACtB,mBAAmB;EACrB;AACF;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".view__container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow-y: auto;\n  gap: 24px;\n}\n\n.view__instructions {\n  font-weight: 600;\n  font-size: var(--font-size-reading);\n}\n\n.view__content {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.report_container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 2rem;\n}\n\n.card__container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.pie_graph__container {\n  width: 100%;\n}\n\n.graph__container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  padding: 20px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  border: 1px solid #e5e5e5;\n  gap: 2rem;\n\n  @media screen and (width <= 768px) {\n    flex-direction: column;\n    align-items: center;\n  }\n}\n\n.title {\n  font-size: 16px;\n  color: #45348e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view__container": `R2jtDw1gUlUyWnpYxdEv`,
	"view__instructions": `zlhPqiY0p31HQNfbormr`,
	"view__content": `i7yC4YG4PFN63sOe9mxn`,
	"report_container": `zppNdook8MfbvwZQAcDc`,
	"card__container": `vURt2LncN8qk6hNcFLZX`,
	"pie_graph__container": `N4J8TGr7hdW90slC8vzG`,
	"graph__container": `s8_9wjPZ4pH51z0ETjFK`,
	"title": `Cwv4IZCyIKPUE7OkJyvd`
};
export default ___CSS_LOADER_EXPORT___;
