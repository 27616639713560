// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ghcEYF1Yp9REZaW2xgpC {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.YjX2q3h6ycf34KySfhac {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;

  @media screen and (width < 788px) {
    width: 100%;
    /* justify-content: center; */
    flex-wrap: wrap;
  }

  @media screen and (width <= 590px) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.ZcQNzdDyeVCCAUaGaNJt {
  width: 100%;
  max-width: 200px;
}

.yi1ZPZT7HaNjLuOEINLI {
  width: 100%;
  max-width: 250px;
}

.ZcQNzdDyeVCCAUaGaNJt,
.yi1ZPZT7HaNjLuOEINLI {
  @media screen and (width <= 590px) {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/ServiceReportFilter/ServiceReportFilter.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,qBAAqB;EACrB,SAAS;;EAET;IACE,WAAW;IACX,6BAA6B;IAC7B,eAAe;EACjB;;EAEA;IACE,sBAAsB;IACtB,qBAAqB;EACvB;AACF;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE;IACE,eAAe;EACjB;AACF","sourcesContent":[".filter__container {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 16px;\n}\n\n.filter__dates {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-end;\n  gap: 16px;\n\n  @media screen and (width < 788px) {\n    width: 100%;\n    /* justify-content: center; */\n    flex-wrap: wrap;\n  }\n\n  @media screen and (width <= 590px) {\n    flex-direction: column;\n    align-items: flex-end;\n  }\n}\n\n.filter__dates__input {\n  width: 100%;\n  max-width: 200px;\n}\n\n.filter__salebox {\n  width: 100%;\n  max-width: 250px;\n}\n\n.filter__dates__input,\n.filter__salebox {\n  @media screen and (width <= 590px) {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter__container": `ghcEYF1Yp9REZaW2xgpC`,
	"filter__dates": `YjX2q3h6ycf34KySfhac`,
	"filter__dates__input": `ZcQNzdDyeVCCAUaGaNJt`,
	"filter__salebox": `yi1ZPZT7HaNjLuOEINLI`
};
export default ___CSS_LOADER_EXPORT___;
