import React, { useEffect, useRef } from "react";
import styles from "./ProductsTable.module.css";
import {
  IProductsReportStore,
  useProductsReportStore,
} from "@Stores/useProductsReportStore";
import { formatToSoles } from "@Utilities/FormatsHandler";
import CustomTable, {
  Column,
} from "@Components/ui/molecules/CustomTable/CustomTable";
import useLoadProductReport from "@Hooks/useLoadProductReport";
import { IProduct } from "@Models/interfaces/products";

const ProductsTable: React.FC = () => {
  const { loadProductReport } = useLoadProductReport();
  const { products, currentPage, setCurrentPage, pages } =
    useProductsReportStore() as IProductsReportStore;
  const prevPageRef = useRef(currentPage);
  const columns: Column<IProduct>[] = [
    { header: "Nombre", accessor: (product: IProduct) => product.name },
    {
      header: "Cantidad vendido",
      accessor: (product: IProduct) => product.quantity,
    },
    {
      header: "Monto total de venta",
      accessor: (product: IProduct) => formatToSoles(product.totalRevenue),
    },
  ];

  useEffect(() => {
    if (prevPageRef.current !== currentPage) {
      loadProductReport();
      prevPageRef.current = currentPage;
    }
  }, [currentPage, loadProductReport]);

  return (
    <CustomTable
      data={products}
      columns={columns as Column<{ id: string | number }>[]}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={pages}
      emptyMessage="No se han encontrado productos con estos parámetros de búsqueda."
    />
  );
};

export default ProductsTable;
