import { create } from "zustand";

// Interfaces
interface IAccountInfo {
  id: number;
  name: string;
  lastName: string;
  alias: string;
  email: string;
  lounge: {
    id: number;
    name: string;
  };
  company: {
    id: number;
    name: string;
  };
  profilePicture: string;
}

interface ISaleDocument {
  id: number;
  // ... otros campos del documento de venta
}

interface ISaleDocumentsStore {
  // Estado de documentos
  saleDocuments: ISaleDocument[];
  currentPagination: number;
  totalPages: number;
  shouldReloadTable: boolean;
  isLoadingDocuments: boolean;
  error: string | null;

  // Estado de cuenta
  accountInfo: IAccountInfo | null;
  isLoadingAccount: boolean;
  accountError: string | null;

  // Acciones para documentos
  setSaleDocuments: (documents: ISaleDocument[]) => void;
  clearSaleDocuments: () => void;
  setIsLoadingDocuments: (isLoading: boolean) => void;
  setShouldReloadTable: (shouldReload: boolean) => void;

  // Acciones para cuenta
  setAccountInfo: (info: IAccountInfo) => void;
  clearAccountInfo: () => void;
  setIsLoadingAccount: (isLoading: boolean) => void;
  setAccountError: (error: string | null) => void;
  updateAccountFromLocalStorage: () => void;

  // Acciones para paginación
  setCurrentPagination: (page: number) => void;
  setTotalPages: (pages: number) => void;
  nextPage: () => void;
  previousPage: () => void;

  // Acciones de error
  setError: (error: string | null) => void;

  // Acción para limpiar todo el store
  clearStore: () => void;
}

const initialState = {
  // Estado inicial de documentos
  saleDocuments: [],
  currentPagination: 1,
  totalPages: 1,
  shouldReloadTable: false,
  isLoadingDocuments: false,
  error: null,

  // Estado inicial de cuenta
  accountInfo: null,
  isLoadingAccount: false,
  accountError: null,

  // Estado inicial de modales
  isSaleDetailsModalOpen: false,
  isCancelTicketModalOpen: false,
  isExportModalVisible: false,
};

export const useSaleDocumentsStore = create<ISaleDocumentsStore>(
  (set, get) => ({
    ...initialState,

    // Acciones para documentos
    setSaleDocuments: (documents) => set({ saleDocuments: documents }),
    clearSaleDocuments: () => set({ saleDocuments: [] }),
    setIsLoadingDocuments: (isLoading) =>
      set({ isLoadingDocuments: isLoading }),
    setShouldReloadTable: (shouldReload) =>
      set({ shouldReloadTable: shouldReload }),

    // Acciones para cuenta
    setAccountInfo: (info) => {
      set({ accountInfo: info });
      // Guardar en localStorage
      localStorage.setItem(
        "account_info",
        JSON.stringify({
          employee: {
            id: info.id,
            name: info.name,
            lastName: info.lastName,
            alias: info.alias,
            email: info.email,
            profileImage: info.profilePicture,
          },
          lounge: info.lounge,
          company: info.company,
        })
      );
    },
    clearAccountInfo: () => {
      set({ accountInfo: null });
      localStorage.removeItem("account_info");
    },
    setIsLoadingAccount: (isLoading) => set({ isLoadingAccount: isLoading }),
    setAccountError: (error) => set({ accountError: error }),
    updateAccountFromLocalStorage: () => {
      const storedInfo = localStorage.getItem("account_info");
      if (storedInfo) {
        try {
          const parsedInfo = JSON.parse(storedInfo);
          set({
            accountInfo: {
              id: parsedInfo.employee.id,
              name: parsedInfo.employee.name,
              lastName: parsedInfo.employee.lastName,
              alias: parsedInfo.employee.alias,
              email: parsedInfo.employee.email,
              lounge: parsedInfo.lounge,
              company: parsedInfo.company,
              profilePicture: parsedInfo.employee.profileImage,
            },
          });
        } catch (error) {
          set({ accountError: "Error al cargar la información de la cuenta" });
        }
      }
    },

    // Acciones para paginación
    setCurrentPagination: (page) => set({ currentPagination: page }),
    setTotalPages: (pages) => set({ totalPages: pages }),
    nextPage: () =>
      set((state) => ({
        currentPagination:
          state.currentPagination < state.totalPages
            ? state.currentPagination + 1
            : state.currentPagination,
      })),
    previousPage: () =>
      set((state) => ({
        currentPagination:
          state.currentPagination > 1
            ? state.currentPagination - 1
            : state.currentPagination,
      })),

    // Acciones de error
    setError: (error) => set({ error }),

    // Limpiar todo el store
    clearStore: () => set(initialState),
  })
);
