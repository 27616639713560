import { STATUS_OK } from "@Models/httpsStatus";
import { getMovements_service } from "@Services/saleboxes";
import { useSalesMovementsStore } from "@Stores/useSalesMovementsStore";
import { convertDate, convertTime } from "@Utilities/DateFormat";
import { capitalizeWords } from "@Utilities/StringFormats";

export const getMovements = async (
  idBox: string,
  movementType: string,
  data: string,
  concept: string,
  startDate: string,
  endDate: string,
  page: number
) => {
  const { setMovements, setTotalPages, setIsLoadingMovements, setError } =
    useSalesMovementsStore.getState();

  try {
    setIsLoadingMovements(true);

    if (idBox === "Todas") {
      idBox = "";
    }
    if (movementType === "Todos") {
      movementType = "";
    }
    if (concept === "Todos") {
      concept = "";
    }

    const payload = {
      saleBoxId: idBox ?? "",
      typeMovement: movementType,
      searchParameter: data,
      typeMovementConcept: concept,
      startDate: startDate,
      endDate: endDate,
      page: page,
    };

    const response: IResponse = await getMovements_service(payload);

    if (!response.success) {
      setMovements([]);
      setError(response.user_message);
      return response;
    }

    const movements = response.data.movement.map((movement) => ({
      saleBoxName: movement.saleBoxName,
      type:
        movement.typeMovement === "SALIDA"
          ? "Egreso"
          : capitalizeWords(movement.typeMovement),
      date: `${convertDate(movement.dateMovement)} - ${convertTime(
        movement.hourMovement
      )}`,
      dateMovement: convertDate(movement.dateMovement),
      hourMovement: convertTime(movement.hourMovement),
      concept: movement.concept,
      observation: movement.observation,
      employeeName: capitalizeWords(movement.employeeName),
      amount: movement.amount,
      saleDocumentId: movement.saleDocumentId,
      modified: movement.modified,
      id: movement.movementId,
    }));

    setMovements(movements);
    setTotalPages(response.data.maxPageSize);
    setError(null);

    return {
      status: STATUS_OK,
      message: "Movimientos de venta obtenidos correctamente",
      data: {
        movement: movements,
        maxPageSize: response.data.maxPageSize,
      },
    };
  } catch (error) {
    setError(error.message);
    return {
      status: 500,
      message: "Error al obtener los movimientos",
      success: false,
      user_message: "Ocurrió un error al obtener los movimientos",
    };
  } finally {
    setIsLoadingMovements(false);
  }
};
