import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ISaleBox } from "@Models/interfaces/saleboxes";

interface SaleBoxesState {
  saleBoxes: ISaleBox[];
  updateSaleBoxes: (saleBoxes: ISaleBox[]) => void;
  updateSaleBoxOnList: (saleBox: ISaleBox) => void;
}

export const useSaleBoxesStore = create<SaleBoxesState>()(
  persist(
    (set) => ({
      saleBoxes: [],
      updateSaleBoxes: (saleBoxes) => set({ saleBoxes }),
      updateSaleBoxOnList: (updatedSaleBox) =>
        set((state) => ({
          saleBoxes: state.saleBoxes.map((saleBox) =>
            saleBox.id === updatedSaleBox.id ? updatedSaleBox : saleBox
          ),
        })),
    }),
    {
      name: "saleBoxes-storage",
    }
  )
);
