import {
  CLIENT_REPORT_NAME,
  PRODUCT_REPORT_NAME,
  SALE_MOVEMENTS_REPORT_NAME,
  SERVICE_REPORT_NAME,
  WAREHOUSE_REPORT_NAME,
  SALE_DOCUMENTS_REPORT_NAME,
} from "@Models/consts";

export const getAvailableReports = [
  {
    name: CLIENT_REPORT_NAME,
    location: "/clientes",
    description:
      "Conoce el comportamiento de tus clientes para aumentar la fidelización.",
  },
  {
    name: PRODUCT_REPORT_NAME,
    location: "/productos",
    description:
      "Obtén insights sobre las ventas de productos para optimizar el inventario.",
  },
  {
    name: SERVICE_REPORT_NAME,
    location: "/servicios",
    description:
      "Analiza las ventas de servicios para mejorar tus estrategias comerciales.",
  },
  {
    name: WAREHOUSE_REPORT_NAME,
    location: "/movimientos-almacenes",
    description:
      "Analiza el estado de tus almacenes para optimizar la gestión de inventario.",
  },
  {
    name: SALE_MOVEMENTS_REPORT_NAME,
    location: "/movimientos-venta",
    description:
      "Analiza los movimientos de venta para mejorar la gestión de tus ventas en caja.",
  },
  {
    name: SALE_DOCUMENTS_REPORT_NAME,
    location: "/documentos-venta",
    description:
      "Analiza los documentos de venta para mejorar la gestión de tus ventas en caja.",
  },
  {
    name: "Mi caja",
    location: "/mi-caja",
    description:
      "Analiza los movimientos de tu caja para mejorar la gestión de tus ventas en caja.",
  },
];
