import { exportProductReport_service } from "@Services/products";
import { downloadCSV } from "@Utilities/DownloadFile";

export const exportProductReport = async (
  shareType: string,
  loungeId: string
) => {
  let response: IResponse;

  switch (shareType) {
    // case 'pdf':
    // 	response = {
    // 		success: true,
    // 		data: null,
    // 		status: 200,
    // 		user_message: 'Reporte exportado correctamente.',
    // 		developer_message: null,
    // 	};
    // 	break;
    case "csv":
      response = await exportProductReport_service(loungeId);

      if (!response.success) {
        return response;
      }

      let file = {
        file: `data:application/pdf;base64,${response.data}`,
        file_name: "Reporte de productos",
      };
      downloadCSV(file);
      return response;
    default:
      break;
  }

  return response;
};
