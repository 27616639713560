import React, { useState } from "react";
// @ts-ignore
import { SaleMovementsReportView } from "@viuti/recursos";
import { getMovements } from "@Adapters/saleboxes/movements/getMovements.adapter";
import { getFirstDayOfMonth, getCurrentDate } from "@Utilities/DateFormat";
import { classCell, typeMovements } from "./consts/constMovements";
import useSelectBox from "./hooks/useSelectBox/useSelectBox";
import { getLoungeEmployees } from "@Adapters/saleboxes/movements/getLoungeEmployees.adapter";
import useConceptsMovements from "./hooks/useConceptsMovements/useConceptsMovements";
import useBoxes from "./hooks/useBoxes/useBoxes";
import { useSalesMovementsStore } from "@Stores/useSalesMovementsStore";
import { useToasterStore, IToasterStore } from "@Stores/useToasterStore";
import {
  EXPORT_MOVEMENTS_UUID,
  REGISTER_MOVEMENTS_UUID,
} from "@Models/consts/securityAccess";
import { getBase64donwloadMovements } from "@Adapters/saleboxes/movements/getBase64donwloadMovements.adapter";
import { useNavigate } from "react-router-dom";

const MovementsView = () => {
  const navigate = useNavigate();
  const { setToaster } = useToasterStore() as IToasterStore;
  const {
    movements,
    currentPage,
    totalPages,
    shouldReloadTable,
    onConceptCreation,
    employees,
    setCurrentPage,
    setShouldReloadTable,
  } = useSalesMovementsStore();

  const [form, setForm] = useState({
    searchParameter: "",
    idBox: "",
    type: "",
    concept: "",
    startDate: getFirstDayOfMonth(),
    endDate: getCurrentDate(),
  });
  const [initialForm, setInitialForm] = useState({
    searchParameter: "",
    idBox: "",
    type: "",
    concept: "",
    startDate: getFirstDayOfMonth(),
    endDate: getCurrentDate(),
  });

  const [isCreationMovementModalVisible, setIsCreationMovementModalVisible] =
    useState(false);
  const [isEditMovementModalVisible, setIsEditMovementModalVisible] =
    useState(false);
  const [isDeleteMovementModalVisible, setIsDeleteMovementModalVisible] =
    useState(false);
  const [isEditingHistoryModalVisible, setIsEditingHistoryModalVisible] =
    useState(false);

  const concepts = useConceptsMovements();
  const boxes = useBoxes();
  const reduxState = {
    concepts,
    movements: {
      currentPagination: currentPage,
      movements: movements,
      pages: totalPages,
      shouldReloadTable: shouldReloadTable,
    },
    saleConfig: {
      loungeEmployees: employees,
    },
  };
  // Se selecciona la caja por defecto cuando se carga la página
  useSelectBox(boxes, boxes.currentBoxId, form, setForm, setInitialForm);

  const triggerToast = ({
    message,
    status,
    toastTimeDuration,
  }: {
    message: string;
    status: number;
    toastTimeDuration: number | string;
  }) => {
    setToaster(message, status, false, toastTimeDuration);
  };

  return (
    <SaleMovementsReportView
      filters={form}
      setFilters={setForm}
      initialFilters={initialForm}
      setInitialFilters={setInitialForm}
      movementConcepts={concepts}
      movementTypes={typeMovements}
      reduxSelector={reduxState}
      onHeaderBackClick={() => navigate("/")}
      getEmployees={getLoungeEmployees}
      getMovements={() =>
        getMovements(
          form.idBox,
          form.type,
          form.searchParameter,
          form.concept,
          form.startDate,
          form.endDate,
          currentPage
        )
      }
      createMovementModalVisibility={{
        isVisible: isCreationMovementModalVisible,
        setIsVisible: setIsCreationMovementModalVisible,
      }}
      editMovementModalVisibility={{
        isVisible: isEditMovementModalVisible,
        setIsVisible: setIsEditMovementModalVisible,
      }}
      deleteMovementModalVisibility={{
        isVisible: isDeleteMovementModalVisible,
        setIsVisible: setIsDeleteMovementModalVisible,
      }}
      editingHistoryModalVisibility={{
        isVisible: isEditingHistoryModalVisible,
        setIsVisible: setIsEditingHistoryModalVisible,
      }}
      classCell={classCell}
      currentDate={getCurrentDate}
      firstDayOfMonth={getFirstDayOfMonth}
      boxesInfo={boxes}
      securityUuids={{
        EXPORT_MOVEMENTS_UUID,
        REGISTER_MOVEMENTS_UUID,
      }}
      onNotification={triggerToast}
      getCsvMovementsReport={() => getBase64donwloadMovements(form)}
      onCurrentPaginationChange={(pagination) => setCurrentPage(pagination)}
      onShouldReloadTableChange={(shouldReload) =>
        setShouldReloadTable(shouldReload)
      }
      useSelectBox={useSelectBox}
      onConceptCreation={(concept) => onConceptCreation(concept)}
      shouldReloadTable={shouldReloadTable}
    />
  );
};

export default MovementsView;
