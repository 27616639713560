// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg width=%27100%25%27 height=%27100%25%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3crect width=%27100%25%27 height=%27100%25%27 fill=%27none%27 rx=%278%27 ry=%278%27 stroke=%27%23F1F1F1FF%27 stroke-width=%276%27 stroke-dasharray=%279%2c 13%27 stroke-dashoffset=%2712%27 stroke-linecap=%27square%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kXBdsHN7Fs_0Qk19O57U {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 32px 22px;
  background-color: #fff;
  cursor: default;
  transition: all 150ms ease-in-out;

  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border-radius: 8px;
}

._YP7AJRuqLpKnmg6Tq2c {
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }
}

.JajdeWtKEMGxolp3WJHb {
  text-align: center;
  font: 800 1rem "Mulish", sans-serif;
  color: #45348e;
}

._ehqftRO2EIZrVEBDK3G {
  font: 600 0.875rem "Mulish", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/EmptyMessage/EmptyMessage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,iCAAiC;;EAEjC,yDAAqT;EACrT,kBAAkB;AACpB;;AAEA;EACE,eAAe;;EAEf;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,cAAc;AAChB;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".empty__container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 1rem;\n  padding: 32px 22px;\n  background-color: #fff;\n  cursor: default;\n  transition: all 150ms ease-in-out;\n\n  background-image: url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23F1F1F1FF' stroke-width='6' stroke-dasharray='9%2c 13' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e\");\n  border-radius: 8px;\n}\n\n.empty__container__action {\n  cursor: pointer;\n\n  &:hover {\n    background-color: #fafafa;\n  }\n}\n\n.empty__message {\n  text-align: center;\n  font: 800 1rem \"Mulish\", sans-serif;\n  color: #45348e;\n}\n\n.empty__submessage {\n  font: 600 0.875rem \"Mulish\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty__container": `kXBdsHN7Fs_0Qk19O57U`,
	"empty__container__action": `_YP7AJRuqLpKnmg6Tq2c`,
	"empty__message": `JajdeWtKEMGxolp3WJHb`,
	"empty__submessage": `_ehqftRO2EIZrVEBDK3G`
};
export default ___CSS_LOADER_EXPORT___;
