/**
 * Convierte una fecha de string a formato dd/mm/yyyy
 * @param dateString - String de fecha en formato ISO (YYYY-MM-DD)
 * @returns String de fecha en formato dd/mm/yyyy
 */
export const convertDate = (dateString: string): string => {
  if (!dateString) return "";

  const date = new Date(dateString);

  // Verificar si la fecha es válida
  if (isNaN(date.getTime())) return "";

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

/**
 * Convierte una hora de string a formato HH:mm
 * @param timeString - String de hora en formato HH:mm:ss
 * @returns String de hora en formato HH:mm
 */
export const convertTime = (timeString: string): string => {
  if (!timeString) return "";

  // Si la hora ya viene en formato HH:mm, la retornamos
  if (timeString.length === 5) return timeString;

  // Si viene en formato HH:mm:ss, removemos los segundos
  return timeString.substring(0, 5);
};

export const getFirstDayOfMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1)
    .toISOString()
    .split("T")[0];
};

// Función para obtener la fecha actual
export const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};
