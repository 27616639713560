import {
  IClient,
  IClientCategory,
  IClientsFilter,
} from "@Models/interfaces/clients";
import { create } from "zustand";

export interface IClientsReportStore {
  filter: IClientsFilter;
  setFilter: (filter: IClientsFilter) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  pages: number;
  setPages: (pages: number) => void;
  clients: IClient[];
  setClients: (clients: IClient[]) => void;
  clientsCategories: IClientCategory[];
  clientsCategoriesFetchResponse: IFetchReportResponse;
  setClientsCategoriesFetchResponse: (response: IFetchReportResponse) => void;
  setClientsCategories: (clientsCategories: IClientCategory[]) => void;
  clientsFetchResponse: IFetchReportResponse;
  setClientsFetchResponse: (response: IFetchReportResponse) => void;
}

export const useClientsReportStore = create((set) => ({
  filter: { loungeId: "", fromDate: "", toDate: "" },
  setFilter: (filter: IClientsFilter) => set({ filter }),
  currentPage: 1,
  setCurrentPage: (page: number) => set({ currentPage: page }),
  pages: 1,
  setPages: (pages: number) => set({ pages }),
  clients: [],
  setClients: (clients: IClient[]) => set({ clients }),
  clientsCategories: [],
  setClientsCategories: (clientsCategories: IClientCategory[]) =>
    set({
      clientsCategories,
    }),
  clientsCategoriesFetchResponse: {
    status: "loading",
    message: "",
  },
  setClientsCategoriesFetchResponse: (response: IFetchReportResponse) =>
    set({ clientsCategoriesFetchResponse: response }),
  clientsFetchResponse: {
    status: "loading",
    message: "",
  },
  setClientsFetchResponse: (response: IFetchReportResponse) =>
    set({ clientsFetchResponse: response }),
}));
