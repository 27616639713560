import { exportClientReport_service } from "@Services/clients";
import { downloadCSV } from "@Utilities/DownloadFile";

export const exportClientReport = async (
  shareType: string,
  loungeId: string
) => {
  let response: IResponse;

  switch (shareType) {
    case "csv":
      const response = await exportClientReport_service(Number(loungeId));

      if (!response.success) {
        return response;
      }

      let file = {
        file: `data:application/pdf;base64,${response.data}`,
        file_name: "Reporte de clientes",
      };
      downloadCSV(file);
      return response;
    default:
      break;
  }

  return response;
};
