// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IbwUpF79WGkQh4up6JgM {
  text-transform: capitalize !important;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/WarehousesTable/WarehousesTable.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,0BAA0B;AAC5B","sourcesContent":[".link {\n  text-transform: capitalize !important;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `IbwUpF79WGkQh4up6JgM`
};
export default ___CSS_LOADER_EXPORT___;
