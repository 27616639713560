import React, { useState, useEffect } from "react";
import style from "./WarehouseReportFilter.module.css";
// @ts-ignore
import { SelectInput, DateInput, ButtonWithoutIcon } from "@viuti/recursos";
import {
  IWarehousesReportStore,
  useWarehousesReportStore,
} from "@Stores/useWarehousesReportStore";
import { IWarehouseOption } from "@Models/interfaces/warehouses";

const WarehouseReportFilter = ({ loadReportAction }) => {
  const { filter, setFilter, warehousesList } =
    useWarehousesReportStore() as IWarehousesReportStore & {
      warehousesList: IWarehouseOption[];
    };
  const { warehouseId, fromDate, toDate } = filter;

  const [isSubmitPending, setIsSubmitPending] = useState(false);

  useEffect(() => {
    if (warehousesList.length > 0 && !filter.warehouseId) {
      const firstWarehouse = warehousesList[0];
      localStorage.setItem(
        "selectedWarehouse",
        JSON.stringify({
          warehouseId: parseInt(firstWarehouse.value),
          warehouseName: firstWarehouse.name,
          sales: firstWarehouse.sale ? 1 : 0,
          warehouseValue: firstWarehouse.value,
        })
      );

      setFilter({
        ...filter,
        warehouseId: firstWarehouse.value,
        warehouseName: firstWarehouse.name,
        sales: firstWarehouse.sale ? 1 : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehousesList]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "warehouseId") {
      const selectedWarehouse = warehousesList.find(
        (w: IWarehouseOption) => w.value === value
      );

      if (selectedWarehouse) {
        localStorage.setItem(
          "selectedWarehouse",
          JSON.stringify({
            warehouseId: parseInt(selectedWarehouse.value),
            warehouseName: selectedWarehouse.name,
            sales: selectedWarehouse.sale ? 1 : 0,
            warehouseValue: selectedWarehouse.value,
          })
        );
      }

      const newFilter = {
        ...filter,
        [name]: value,
        warehouseName: selectedWarehouse?.name || "",
        sales: selectedWarehouse?.sale ? 1 : 0,
      };
      setFilter(newFilter);
    } else {
      const newFilter = { ...filter, [name]: value };
      setFilter(newFilter);
    }
  };

  const onSubmit = async () => {
    setIsSubmitPending(true);
    await loadReportAction();
    setIsSubmitPending(false);
  };

  return (
    <div className={style.filter__container}>
      <span className={style.filter__salebox}>
        <SelectInput
          label="Almacén"
          value={warehouseId}
          items={warehousesList}
          handleChange={handleValueChange}
          name="warehouseId"
        />
      </span>
      <span className={style.filter__dates}>
        <span className={style.filter__dates__input}>
          <DateInput
            label="Desde"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: fromDate,
              onChange: handleValueChange,
              name: "fromDate",
            }}
          />
        </span>
        <span className={style.filter__dates__input}>
          <DateInput
            label="Hasta"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: toDate,
              onChange: handleValueChange,
              name: "toDate",
            }}
          />
        </span>
        <ButtonWithoutIcon
          isPrimary={false}
          textBttn={"Cargar"}
          handleClick={onSubmit}
          disabled={!warehouseId || !fromDate || !toDate || isSubmitPending}
          isLoading={isSubmitPending}
        />
      </span>
    </div>
  );
};

export default WarehouseReportFilter;
