export const BASE_ROUTE = "/reportes";

export const ROUTES = {
  HOME_ROUTE: "/",
  SERVICES_REPORTS_ROUTE: "/servicios",
  PRODUCTS_REPORTS_ROUTE: "/productos",
  CLIENTS_REPORTS_ROUTE: "/clientes",
  WAREHOUSES_REPORT_ROUTE: "/movimientos-almacenes",
  SALE_MOVEMENTS_ROUTE: "/movimientos-venta",
  SALE_DOCUMENTS_ROUTE: "/documentos-venta",
  // My caja
  MY_SALEBOX_ROUTE: "/mi-caja",
};
