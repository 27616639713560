import { STATUS_OK } from "@Models/httpsStatus";
import { getSaleBoxes_service } from "@Services/saleboxes";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";
import { useSaleBoxesStore } from "@Stores/useSaleBoxesStore";
import { ISaleBox } from "@Models/interfaces/saleboxes";

export const getSaleBoxes = async () => {
  const { setToaster } = useToasterStore.getState() as IToasterStore;
  const { setSaleBoxes, setIsLoadingSaleBoxes, setError } =
    useSaleBoxesStore.getState();

  try {
    setIsLoadingSaleBoxes(true);

    const response: IResponse = await getSaleBoxes_service();

    if (!response.success) {
      setToaster("Hubo un error al obtener las cajas", 400, false);
      setError("Error al obtener las cajas");
      return response;
    }

    // Formatear las cajas para incluir el campo value requerido por los selectores
    const formattedBoxes: ISaleBox[] = response.data.saleBoxList?.map(
      (saleBox: any) => {
        return {
          id: saleBox.idSaleBox,
          name: saleBox.saleBoxName,
          isOpen: saleBox.estado === 1,
          hasElectronicBill: saleBox.electronicBilling,
          businessName: saleBox.businnesName,
          businessAddress: saleBox.businneAddress,
          ruc: saleBox.ruc,
          localName: saleBox.localName,
          needsCashCount: saleBox.cashCountConflict,
          isDowngraded: Boolean(saleBox.isDowngrade),
        };
      }
    );

    setSaleBoxes(formattedBoxes);
    setError(null);

    return {
      status: STATUS_OK,
      message: "Cajas obtenidas correctamente",
      success: true,
      data: formattedBoxes,
    };
  } catch (error) {
    setToaster("Error inesperado al obtener las cajas", 500, false);
    setError("Error inesperado al obtener las cajas");
    return {
      status: 500,
      message: "Error al obtener las cajas",
      success: false,
      user_message: "Ocurrió un error al obtener las cajas",
    };
  } finally {
    setIsLoadingSaleBoxes(false);
  }
};
