import { STATUS_OK } from "@Models/httpsStatus";
import { getConceptMovements_service } from "@Services/movements";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";
import { useSalesMovementsStore } from "@Stores/useSalesMovementsStore";

export const getConceptsMovements = async () => {
  const { setToaster } = useToasterStore.getState() as IToasterStore;
  const {
    setConcepts,
    setEntryConcepts,
    setOutputConcepts,
    setIsLoadingConcepts,
    setError,
  } = useSalesMovementsStore.getState();

  try {
    setIsLoadingConcepts(true);

    const response: IResponse = await getConceptMovements_service();

    if (response.status !== STATUS_OK) {
      setToaster(
        "Hubo un error al obtener los conceptos de movimiento",
        400,
        false
      );
      setError("Error al obtener los conceptos de movimiento");
      return response;
    }

    const formattedConcepts = response.data.map((concept: any) => ({
      value: concept.idSaleBoxMovementConcept,
      name: concept.description,
      movementType: concept.movementType,
    }));

    // Separar conceptos por tipo
    const entryConcepts = formattedConcepts
      .filter((concept) => concept.movementType === 1)
      .map((concept) => ({
        name: concept.name,
        value: concept.value.toString(),
      }));

    const outputConcepts = formattedConcepts
      .filter((concept) => concept.movementType === 2)
      .map((concept) => ({
        name: concept.name,
        value: concept.value.toString(),
      }));

    // Actualizar el store
    setConcepts(formattedConcepts);
    setEntryConcepts(entryConcepts);
    setOutputConcepts(outputConcepts);
    setError(null);

    return {
      status: STATUS_OK,
      message: "Conceptos obtenidos correctamente",
      success: true,
      data: formattedConcepts,
    };
  } catch (error) {
    setToaster("Error inesperado al obtener los conceptos", 500, false);
    setError("Error inesperado al obtener los conceptos");
    return {
      status: 500,
      message: "Error al obtener los conceptos",
      success: false,
      user_message: "Ocurrió un error al obtener los conceptos",
    };
  } finally {
    setIsLoadingConcepts(false);
  }
};
