import axios from "axios";
import { config, saleboxesBaseUrl } from "./constGlobals";
import { IGetMovements } from "@Models/interfaces/saleboxes";

export const getSaleBoxes_service = async (): Promise<IResponse> => {
  const url = `${saleboxesBaseUrl}/SaleBox/SaleBox`;
  try {
    const response = await axios.get(url, config).then((res) => res.data);
    return {
      success: true,
      status: response.status,
      user_message: response.user_message || "Cajas obtenidas correctamente",
      developer_message: response.developer_message,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        "Hubo un error al obtener las cajas de venta. Por favor, intente nuevamente.",
      developer_message: null,
      data: [],
    };
  }
};

export const getMovements_service = async (
  payload: IGetMovements
): Promise<IResponse> => {
  const url = `${saleboxesBaseUrl}/CashMovements/GetMovements?saleBoxId=${payload.saleBoxId}&typeMovement=${payload.typeMovement}&typeMovementConcept=${payload.typeMovementConcept}&startDate=${payload.startDate}&endDate=${payload.endDate}&searchParameter=${payload.searchParameter}&page=${payload.page}`;
  // const carga_movimientos = 58;
  // trackUserAction(carga_movimientos);
  try {
    const response = await axios.get(url, config).then((res) => res.data);
    return {
      success: true,
      status: response.status,
      user_message: response.user_message,
      developer_message: response.developer_message,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status || 500,
      user_message:
        error?.response?.data.message || "Error al obtener los movimientos",
      developer_message: null,
      data: [],
    };
  }
};
