import { IClient, IClientCategory } from "@Models/interfaces/clients";

const generateRandomId = () => Math.floor(Math.random() * 10000);

export const CATEGORIES_MOCKS: IClientCategory[] = [
  { id: "1", name: "Gold" },
  { id: "2", name: "Platinum" },
  { id: "3", name: "Silver" },
  { id: "4", name: "Bronze" },
];

const names = [
  "John Smith",
  "Jane Doe",
  "Michael Johnson",
  "Emily Davis",
  "Chris Brown",
  "Jessica Wilson",
  "David Anderson",
  "Sarah Thomas",
  "James Jackson",
  "Laura White",
  "Daniel Harris",
  "Emma Martin",
  "Matthew Thompson",
  "Olivia Garcia",
  "Andrew Martinez",
  "Sophia Robinson",
  "Joshua Clark",
  "Mia Rodriguez",
  "Benjamin Lewis",
  "Amelia Walker",
  "Samuel Hall",
  "Isabella Young",
  "Jacob Allen",
  "Charlotte King",
  "Lucas Wright",
  "Ava Scott",
  "Henry Green",
  "Abigail Adams",
  "Alexander Baker",
  "Lily Gonzalez",
  "Christopher Nelson",
  "Madison Carter",
  "Jackson Mitchell",
  "Harper Perez",
  "Sebastian Roberts",
  "Evelyn Turner",
  "Jack Phillips",
  "Grace Campbell",
  "Owen Parker",
  "Lillian Evans",
  "Elijah Edwards",
  "Zoe Collins",
  "Levi Stewart",
  "Hannah Morris",
  "Wyatt Sanchez",
  "Ella Rogers",
  "Carter Reed",
];

export const CLIENTS_MOCKS: IClient[] = names.map((name) => ({
  id: generateRandomId(),
  name,
  lastVisitDate: new Date(
    2024,
    Math.floor(Math.random() * 12),
    Math.floor(Math.random() * 28) + 1
  )
    .toISOString()
    .split("T")[0],
  visitCount: Math.floor(Math.random() * 100),
  totalSpent: parseFloat((Math.random() * 10000).toFixed(2)),
  birthday: new Date(
    1980 + Math.floor(Math.random() * 40),
    Math.floor(Math.random() * 12),
    Math.floor(Math.random() * 28) + 1
  )
    .toISOString()
    .split("T")[0],
  category:
    CATEGORIES_MOCKS[Math.floor(Math.random() * CATEGORIES_MOCKS.length)],
}));
